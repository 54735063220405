$optwear-blue-light: #00a5da;
$optwear-blue-strong: #1d809b;
$color-background: #cccccc;
$optwear-white: #ffffff;
$optwear-container-width: 1200px;
$optwear-blue-gradient: linear-gradient(to left, $optwear-blue-light, $optwear-blue-strong);
$optwear-orange-light: #fab46f;
$optwear-orange-middle: #ea6b41;
$optwear-orange-strong: #e55734;
$optwear-buy-button: #2BC253;
$optwear-orange-gradient: linear-gradient(to left, $optwear-orange-light, $optwear-orange-strong);
$basic-font-family: -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
$decor-font-family: "Futura PT Book", "Arial", sans-serif;
$bold-font-family: "Futura PT Demi", "Arial", sans-serif;
$light-font-family: "FuturaFuturisLightC", "Arial", sans-serif;
$second-font-family: "Helvetica neue", Helvetica, Verdana, sans-serif;
$roboto-font-family: "Roboto", "Arial", sans-serif;
$color-online: desaturate(#00ff00, 55%);
$color-text: #000000;
$color-text-invert: invert($color-text);
$color-primary: $optwear-blue-strong;
$color-accent: $optwear-orange-strong;
$color-background-invert: $color-primary;
$color-text-weak: #5f6368;
$color-text-strong: #004d97;
$color-warning: #e03838;
$color-text-strong-invert: $color-background;
$color-text-link: $color-text-strong;
$color-text-link-visited: #00a5da;
$color-text-link-hover: $color-text-strong;
$color-border: $color-background;
$color-topic-background: darken($optwear-white, 15%);
$border-radius-main: 5px;
$optwear-red: #F44336;
$optwear-green: #8BC34A;
$optwear-yellow: #FFEB3B;
$optwear-blue: #1565C0;
$optwear-orange: #FF9800;
$optwear-purple: #9C27B0;
$optwear-indigo: #3F51B5;
$optwear-cyan: #00BCD4;
$optwear-brown: #795548;
$optwear-grey: #9E9E9E;


$offset-size-header: 50px;

$grid-size: (
	max-width: (lg: 960px, md: 100%),
		padding: (md: 0 8px)
);
@import "@Scss/old/common/_mixins.scss";