@use "src/assets/scss/utils" as u;

.bottom-menu {
  height: u.size('unit-4x');
  line-height: u.size('unit-4x');
  z-index: u.z-index('before-modal');
  position: fixed;
  @include u.below('md') {
    display: none;
  }
  padding: 0 15px;
  background: darken($color-background, 5%);
  font-size: 12px;
  border: 1px solid #e8e8e8;
  border-radius: u.size('unit');
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: 13px;
    color: #585858;
  }
}

.jwh-tips {
  position: fixed;
  bottom: 0;
  //right: 360px;
  left: 0;
  width: 300px;
  height: 500px;
  background: #f5f5f5;
  padding: 15px;
  z-index: u.z-index('modal');
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  font-size: u.size('text-sm') !important;
  @include u.below('md') {
    display: none;
  }
}

.jwh-tips .jwh-tip {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  overflow: hidden;
}

.jwh-tips .jwh-tip:LAST-CHILD {
  padding-bottom: 0;
  border-bottom: none;
}

.jwh-tips .jwh-num, .jwh-tips .jwh-text {
  display: block;
  float: left;
  font-size: 12px;
  line-height: 20px
}

.jwh-tips .jwh-num {
  width: 30px;
  height: 30px;
  border: 1px solid #555;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: #555;
}

.jwh-tips .jwh-text {
  width: 200px;
  margin-left: 15px;
}

.jwhelp {
  @include u.below('md') {
    display: none;
  }
  position: fixed;
  left: 300px;
  bottom: 0;
  width: 360px;
  padding: 15px 35px 45px 15px;
  background: #fff;
  border: #ddd solid 1px;
  opacity: 0;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
  font-size: u.size('text-sm') !important;
  z-index: u.z-index('modal');
  letter-spacing: normal;
  line-height: normal;
  box-sizing: border-box;
  height: 500px;

  .btn {
    width: 100%;
  }

  .jwh-text {
    position: relative;
    height: 75px;
  }

  textarea {
    position: absolute;
    right: 0;
    left: 0;
    padding-left: 32px;
    padding-top: 12px;
    float: none;
    height: 60px;
    border-radius: 2px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    width: 100%;
  }

  textarea:focus {
    box-shadow: none !important;
    border: 1px solid #aaa;
  }

  .icon-inside {
    position: absolute;
    top: 16px;
    left: 12px;
    z-index: 1;
  }

  .close {
    position: absolute;
    top: -15px;
    right: 5px;
    padding: 4px 10px 8px;
    color: #B0B0B0;
    background: #E8E8E8;
    border-radius: 50%;
    opacity: 1;
    height: 30px;
    width: 30px;
  }

  .close:hover {
    background: #E0E0E0;
  }

  #help-results div:LAST-CHILD {
    margin-bottom: 15px;
  }

  a {
    color: #0067E1;
  }

  a.muted {
    color: #999;
  }

  #submit.disabled {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .jwh-contacts {
    margin-top: 10px;
  }

  .jwh-contacts input {
    display: inline-block;
    height: 20px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #555;
    vertical-align: middle;
    background-color: #fff;
    transition: border linear .2s, box-shadow linear .2s;
    width: 229px;
    padding: 4px 6px 3px 120px;
    border: 1px solid #ddd;
    border-radius: 2px;
    box-shadow: none !important;
  }

  .jwh-contacts label {
    position: absolute;
    width: 100px;
    margin: 1px;
    padding: 5px;
    background: #f5f5f5;
    color: #999;
    display: block;
  }

  .jwh-success {
    margin: 40px 0;
    padding-top: 50px;
  }

  .jwh-error {
    margin: 40px 0;
    padding-top: 50px;
  }

  textarea.jwh-incorret {
    border-color: #a40000;
  }

  #feedback-g-recaptcha {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }

  .text-error {
    display: block;
    color: #f44e4e;
    padding-top: 4px;
    padding-bottom: 10px;
  }

  .jwh-actions small {
    color: #909090;
    font-size: 10px;
    line-height: 13px;
    display: block;
    margin-top: 10px;
  }

  .jwh-actions small a {
    color: #505050;
  }
}
