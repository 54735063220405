@use "src/assets/scss/utils" as u;

.footer {
  background-color: u.var-color('grey-20');
  //margin: u.size('unit-5x') 0 0;
  font-size: u.size('text-sm');
}


.logo {
  svg {
    width: 150px;
    height: auto;
  }
}

.col {
  display: flex;
  flex-direction: column;

}

.title {
  font-weight: 700;
  margin: 0 0 u.size('unit-2x');
}

.list {
  list-style: none;
  width: 100%;
  line-height: u.size('line-height-list');
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include u.mixins-block(item) {

  }
}

.panel {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: u.size('unit-2x');
  grid-template-rows: auto;
  padding: u.size('unit-3x') 0;
}

.info {

}

.categories {

}

.other {

}

.bottom-line {
  grid-column: full;
  background: u.var-color('text');
  font-size: u.size('text-sm');
  color: u.var-color(text-ltr);
  @include u.mixins-text-height(u.size('unit-4x'));

  @include u.mixins-block(panel) {
    display: flex;
    justify-content: space-between;
  }


  @include u.mixins-block(links) {
    display: flex;

    @include u.mixins-block(item) {
      color: u.var-color(text-ltr);
      &:not(:last-child) {
        margin: 0 u.size('unit-3x') 0 0;
      }
    }
  }
}

.buttons-scroll {
  position: fixed;
  right: u.size('unit-4x');
  bottom: u.size('unit-2x');
  display: flex;
  flex-direction: column;
  z-index: u.z-index('body-overlay');

  @include u.below('md') {
    right: u.size('unit-2x');
    bottom: u.size('unit-10x');
    @media not all and (min-resolution: .001dpcm) {
      @supports (-webkit-appearance:none) {
        bottom: u.size('unit-10x') + u.size('unit-2x');
      }
    }
  }

  @media screen and (orientation: landscape) and (max-width: (u.size('md') - 1)) {
    right: u.size('unit');
    bottom: u.size('unit-7x');
    @media not all and (min-resolution: .001dpcm) {
      @supports (-webkit-appearance:none) {
        bottom: u.size('unit-7x') + u.size('unit-2x');
      }
    }
  }

  @include u.below('sm') {
    right: u.size('unit');
    bottom: u.size('unit-7x');
    @media not all and (min-resolution: .001dpcm) {
      @supports (-webkit-appearance:none) {
        bottom: u.size('unit-7x') + u.size('unit-2x');
      }
    }
  }
}

.button-scroll {
  background-color: u.var-color('primary-lt') !important;
  color: #fff !important;
  @include u.square(u.size('unit-6x'));
  border-radius: u.size('unit');
  @include u.mixins-box-shadow-md(6);
  display: flex;
  border: none;

  justify-content: center;
  align-items: center;
  transition: opacity .25s ease-in-out, visibility .25s ease-in-out;

  &:not(:last-child) {
    margin: 0 0 u.size('unit-2x');

    @include u.below('md') {
      margin: 0 0 u.size('unit');
    }
  }

  @include u.below('sm') {
    @include u.square(u.size('unit-5x'));
  }

  &-hidden {
    opacity: 0;
    visibility: hidden;
  }

  &-icon {
    display: flex;

    svg {
      @include u.square(u.size('unit-3x'));

      @include u.below('sm') {
        @include u.square(u.size('unit-2x'));
      }
    }

  }

  //@include u.below('md') {
  //  &[data-scroll-position='bottom'] {
  //    display: none;
  //  }
  //}
}
