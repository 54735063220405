@use "src/assets/scss/utils" as u;

:global {
  @each $point, $value in u.$breakpoints {
    .below-#{$point} {
      @include u.below($point) {
        display: none !important;
      }
    }
  }

  @each $point, $value in u.$breakpoints {
    .above-#{$point} {
      @include u.above($point) {
        display: none !important;
      }
    }
  }

  .scroll {

    &.hover {
      .scroll-vertical-track {
        opacity: 0;
        visibility: hidden;
        transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
      }

      @include u.mixins-hover {
        .scroll-vertical-track {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &-vertical-track {
      background-color: u.var-color('grey-15');
      border-radius: u.size('unit-half');

    }
  }

  .tippy-tooltip-content {
    @include u.below('xs') {
      font-size: u.size('text-sm');
    }
  }

  .visuallyhidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    border: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    white-space: nowrap; /* 1 */
  }

  .simplebar-scrollbar {
    &:before {
      background: u.color('grey-15') !important;
      border-radius: u.size('unit') !important;
      opacity: 1 !important;
    }
  }

  .simplebar-vertical > .simplebar-scrollbar {
  }
}
