@use "src/assets/scss/utils" as u;

.header-middle {
  background-color: u.var-color('white');
  height: 2 * u.size('unit-6x');
  align-content: stretch;
  transition: background-color .15s ease-in-out, box-shadow .15s ease-in-out;
  @include u.below('md') {
    z-index: u.z-index('header-middle');
    position: fixed;
    top: 0;
    left: 0;
    @include u.mixins-box-shadow-md(3);
    height: u.size('unit-6x');

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
      background: linear-gradient(180deg, rgba(#000, .5) 0%, rgba(#000, 0) 100%);
    }
  }

  &:global(.scroll), &:global(.scroll-important) {
    background-color: transparent;
    @include u.below('md') {
      box-shadow: none;
      &:before {
        opacity: 1;
        visibility: visible;
      }
    }

    .icon-btn {
      color: #fff !important;
    }

    .logo {
      > a {
        transform: translate(0, -100%);
      }
    }

    .top-btn {
      transform: translate(0, 0);
    }
  }
}

.icon {
  @include u.mixins-block(btn) {
    color: u.var-color('text-lt') !important;
    @include u.square(u.size('unit-6x'));
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color .15s ease-in-out;
  }
}

.panel {
  display: grid;
  grid-template: "logo b c" 1fr / auto 1fr auto;
  @include u.below('md') {
    grid-template: "a logo c" 1fr / auto 1fr auto;
    grid-column: full;
  }
}

.top-btn {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform .15s ease-in-out;
  transform: translate(0, 100%);
  color: #fff !important;
}

.logo {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: logo;
  @include u.below('md') {
    a {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: transform .15s ease-in-out;
    }
    svg {
      height: u.size('unit-5x');
    }
    img {
      height: u.size('unit-5x');
      width: auto;
    }
  }
}

.search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 u.size('unit-5x') u.size('unit');
  width: 100%;
  position: relative;
  @include u.below('md') {
    position: fixed;
    left: 0;
    right: 0;
    padding: 0;
    height: 100%;
    //width: var(--header-mobile-block-width);
    width: 0;
    box-sizing: border-box;
    z-index: u.z-index('before-modal');
    font-size: u.size('text-sm');
  }

  &-container {
    width: 100%;
    transition: transform .25s ease-in-out;
    @include u.below('md') {
      position: fixed;
      right: 0;
      top: 0;
      height: 100%;
      background-color: u.var-color('white');
      width: var(--header-mobile-block-width);
      z-index: u.z-index('before-modal');
      box-sizing: border-box;
      //flex-direction: column;
      transition: transform .15s ease-in;
      padding: 0;
      transform: translate(var(--header-mobile-search-translate), 0);
      overflow: hidden;
      overflow-y: auto;
    }

  }


  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: u.var-color('body-overlay');
    opacity: 0;
    visibility: hidden;
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
    z-index: u.z-index('body-overlay');
    @include u.above('md') {
      display: none;
    }
  }


  @include u.mixins-block(open) {
    @include u.above('md') {
      :global #searchBoxInput {
        border-radius: u.size('unit') u.size('unit') 0 0 !important;
        border-width: 1px 1px 0 1px !important;
      }
    }
    :global #siteSearchAutocomplete {
      opacity: 1;
      visibility: visible;
    }
  }

  &-form {
    width: 100%;
    display: flex;
    align-items: flex-end;
    @include u.below('md') {
      background: url('~@Imageset/blocks/header/search.png') no-repeat;
      background-size: 100% auto;
      padding: u.size('unit-3x') u.size('unit') u.size('unit');
      height: u.size('unit-7x') * 2;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
    }

    &-title {
      font-size: u.size('text-md');
      color: u.var-color('white');
    }

    &-search {
      line-height: u.size('unit-5x') !important;
      height: u.size('unit-5x') !important;
      margin: 0 !important;
      padding: u.size('unit-2x') !important;
      border: 1px solid #333;
      @include u.below('md') {
        padding: 0 u.size('unit') !important;

      }
    }
  }

  @include u.mixins-block(words) {
    overflow: hidden;

    width: 100%;
    @include u.above('md') {
      font-size: u.size('text-xs');
    }
    @include u.mixins-block(list) {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      height: u.size('unit-3x');
      padding: u.size('unit') 0 0;
      @include u.below('md') {
        height: auto;
        flex-direction: column;
        padding: 0 u.size('unit');
        overflow: hidden;
      }
      @include u.mixins-block(item) {

        @include u.above('md') {
          &:first-child {
            border-width: 0;
            padding: 0 u.size('unit') 0 0;
          }
          &:last-child {
            padding: 0 0 0 u.size('unit');
          }
        }
        @include u.below('md') {
          width: 100%;
          @include u.mixins-text-height(u.size('unit-6x'));
          border: none;
          padding: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        padding: 0 u.size('unit');
        border-style: solid;
        border-width: 0 0 0 1px;
        border-color: u.var-color('grey-10');
        @include u.mixins-block(link) {
          color: u.var-color('text-lt');
        }
      }
    }
  }
  @include u.mixins-block(helper) {
    width: 100%;
    position: relative;
    @include u.below('md') {

    }
  }

  @include u.mixins-block(autocomplete) {
    z-index: u.z-index('search-autocomplete');
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: u.var-color('white');
    opacity: 0;
    visibility: hidden;
    transition: u.change('visibility-transition-default');

    > :global(.ui-widget-content) {
      background: u.var-color('white');
      position: relative;
      @include u.below('md') {
        padding: 0 u.size('unit');
      }
      @include u.above('md') {
        max-height: 256px;
        border-color: u.var-color('grey-20');
        border-style: solid;
        border-width: 0 1px 1px;
        border-radius: 0 0 u.size('unit') u.size('unit');
        overflow-y: auto;
      }
    }

    @include u.mixins-block(list) {
      display: block;
      @include u.mixins-block(item) {
        @include u.mixins-text-height(u.size('unit-4x'));
        width: 100%;
        cursor: pointer;
        transition: background-color u.time('default') u.ease('default');
        display: flex;
        align-items: center;
        font-size: u.size('text-sm');
        @include u.below('md') {
          @include u.mixins-text-height(u.size('unit-6x'));
        }
        @include u.above('md') {
          padding: 0 u.size('unit') 0 u.size('unit-2x');
        }
        @include u.mixins-hover {
          background-color: u.var-color('grey-20');
        }
        @include u.mixins-block(text) {
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0 u.size('unit-half') 0 0;
          @include u.mixins-block(hightlight) {
            font-weight: 700;
          }
        }
        @include u.mixins-block(counter) {
          font-size: u.size(text-xs);
        }
      }
    }
  }
}

html:global(.search-mobile-open) {
  @include u.below('md') {
    @include u.below('md') {
      .search {
        &-overlay {
          visibility: visible;
          opacity: 1;
        }

        &-container {
          transform: translate(0, 0);
        }
      }
    }
  }
}

.tools {
  display: flex;
  align-items: center;
}

.auth {
  position: relative;
  display: flex;
  align-items: center;
  font-size: u.size('text-sm');
  @include u.mixins-block(login) {

    display: flex;
    align-items: center;
    margin: 0 0 0 u.size('unit-2x');
    padding: 0 0 0 u.size('unit-2x');
    border-left: 1px solid u.var-color('grey-10');
    svg {
      margin: 0 u.size('unit') 0 0;
    }
    @include u.mixins-hover {
      ~ .auth-window {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &-register {
    font-weight: 500;

  }

  @include u.mixins-block(window) {
    border-radius: u.size('unit');
    @include u.mixins-box-shadow-md(6);
    width: 400px;
    position: absolute;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    right: 0;
    display: flex;
    flex-direction: column;
    @include u.mixins-hover {
      opacity: 1;
      visibility: visible;
    }
    align-items: center;
    transition: u.change('visibility-transition-default');
    z-index: u.z-index('auth-window');
    background: u.var-color('white');
    border: 1px solid u.var-color('grey-10');
    padding: u.size('unit-3x');
    @include u.mixins-block(extenrals) {
      padding: 0 0 u.size('unit-2x');
      margin: 0 0 u.size('unit-2x');
      border: 1px solid u.var-color('grey-1');
      width: 100%;
      :global .ulogin-buttons-container {
        display: flex !important;
        justify-content: space-between !important;
        width: 100% !important;


        [role="button"] {
          margin: 0 !important;
          border-radius: 100% !important;
          box-shadow: 0 0 5px u.var-color('grey-15');
        }
      }
    }
    @include u.mixins-block(forget-link) {
      color: u.var-color('text-ltr');
    }
    @include u.mixins-block(register) {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      @include u.mixins-block(link) {
        color: u.var-color('secondary');
      }
    }
  }
}

header {
  .auth-window-register-link {
    color: u.var-color('secondary') !important;
    margin: 0 0 0 u.size('unit');
  }
}

.actions {
  display: none;
  grid-template: 1fr / repeat(4, auto);
  grid-gap: 0 u.size('unit');

  &:global(.auth) {
    display: grid;
  }

  @include u.below('md') {
    display: grid;
    height: u.size('unit-9x');
    background-color: u.var-color('white');
    width: 100%;
    box-shadow: 0 6px 10px 0 u.var-color('box-shadow-14'), 0 1px 18px 0 u.var-color('box-shadow-12'), 0 3px 5px -1px u.var-color('box-shadow-20');
    grid-template: 1fr / repeat(5, 1fr);
    @include u.mixins-box-shadow-md(6);
    //transition: transform .15s ease-in-out;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: u.z-index('header-tools');
    @media not all and (min-resolution: .001dpcm) {
      @supports (-webkit-appearance:none) {
        height: u.size('unit-9x') + u.size('unit-2x');
        padding: 0 0 u.size('unit-2x');
      }
    }
    &:global(.hide) {
      @include u.below('md') {
        transform: translateY(100%);
      }
    }
  }

  @media screen and (orientation: landscape) and (max-width: (u.size('md') - 1)) {
    height: u.size('unit-6x');
    @media not all and (min-resolution: .001dpcm) {
      @supports (-webkit-appearance:none) {
        height: u.size('unit-6x') + u.size('unit-2x');
        padding: 0 0 u.size('unit-2x');
      }
    }
  }

  @include u.below('sm') {
    height: u.size('unit-6x');
    @media not all and (min-resolution: .001dpcm) {
      @supports (-webkit-appearance:none) {
        height: u.size('unit-6x') + u.size('unit-2x');
        padding: 0 0 u.size('unit-2x');
      }
    }
  }

  @include u.mixins-block(item) {
    padding: 0 u.size('unit-2x') 0 0;

    @include u.below('lg') {
      padding: 0 u.size('unit-3x') 0 0;
    }

    @include u.below('md') {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    @include u.mixins-block(link) {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;


      @include u.mixins-block(icon) {
        color: u.var-color('grey-50');
        margin: 0 0 u.size('unit-half');
        svg {
          @include u.square(28px) ;
          @include u.below('md') {
            @include u.square(u.size('unit-3x'))
          }
        }

        @include u.below('sm') {
          margin: 0;
        }
      }

      @include u.mixins-block(label) {
        color: u.var-color('text-lt');
        font-size: u.size('text-xs');
        @include u.below('lg') {
          display: none;
        }
      }

      @include u.mixins-block(counter) {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(calc(-50% + 20px), calc(-50% - 20px));
        display: flex;
        justify-content: center;
        align-items: center;
        height: u.size('unit-2x');
        min-width: u.size('unit-3x');
        font-size: u.size('text-xs');
        color: u.var-color('white');
        border-radius: u.size('unit-2x');
        //padding: 0 u.size('unit-half');
        background-color: u.var-color('primary-dk');
        padding: 0 u.size('unit-half');

        @include u.below('lg') {
          transform: translate(calc(-50% + 15px), calc(-50% - 15px));
        }
        @include u.below('md') {
          transform: translate(calc(-50% + #{u.size('unit-2x')}), calc(-50% - #{u.size('unit-2x')}));
        }
        @media screen and (orientation: landscape) and (max-width: (u.size('md') - 1)) {
          transform: translate(calc(-50% + #{u.size('unit-2x')}), calc(-50% - #{u.size('unit')}));
        }
        @include u.below('xs') {
          transform: translate(calc(-50% + #{u.size('unit-2x')}), calc(-50% - #{u.size('unit')}));
        }
      }

      @include u.mixins-hover {
        color: u.var-color('primary');
        > .actions-item-link-icon, .actions-item-link-label {
          color: u.var-color('primary-lt');
        }
      }
    }
  }

}

:global(.search-react) {
  .header-middle {
    background-color: u.var-color('white');
    height: u.size('unit-10x') + u.size('unit');
    align-content: stretch;
    transition: background-color .15s ease-in-out, box-shadow .15s ease-in-out;
    @include u.below('md') {
      z-index: u.z-index('header-middle');
      position: fixed;
      top: 0;
      left: 0;
      @include u.mixins-box-shadow-md(2);
      height: #{36 * 2 + 24}px;
      padding: 0 u.size('unit');

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
        background: linear-gradient(180deg, rgba(#000, .5) 0%, rgba(#000, 0) 100%);
      }
    }

    &:global(.scroll), &:global(.scroll-important) {
      background-color: transparent;
      @include u.below('md') {
        box-shadow: none;
        &:before {
          opacity: 1;
          visibility: visible;
        }
      }

      .icon-btn {
        color: #fff !important;
      }

      .logo {
        > a {
          transform: translate(0, -100%);
        }
      }

      .top-btn {
        transform: translate(0, 0);
      }
    }
  }

  .icon {
    @include u.mixins-block(btn) {
      color: u.var-color('text-lt') !important;
      @include u.square(u.size('unit-6x'));
      display: flex;
      justify-content: center;
      align-items: center;
      transition: color .15s ease-in-out;
    }
  }

  .panel {
    position: relative;
    //display: grid;
    //grid-template: "logo a b" 1fr / auto 1fr auto;
    display: flex;
    flex-wrap: nowrap;
    @include u.below('md') {
      grid-template: "a logo c" 1fr / auto 1fr auto;
      grid-column: full;
    }
  }

  .header-react {
    display: flex;
    align-items: center;
    padding: 0 u.size('unit-2x') 0 u.size('unit-3x');
    flex: 1;


    :global(#HEADER_REACT_MODULE_FALLBACK) {
      width: 100%;
    }

    :global(#HEADER_REACT_MODULE) {
      width: 100%;

      @include u.below('md') {
        height: 100%;
      }

      ~ :global(#HEADER_REACT_MODULE_FALLBACK) {
        width: auto;
      }
    }

    @include u.below('md') {
      padding: u.size('unit') 0;
      width: 100%;
    }
  }

  .top-btn {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform .15s ease-in-out;
    transform: translate(0, 100%);
    color: #fff !important;
  }

  .logo {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: logo;
    @include u.below('md') {
      a {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform .15s ease-in-out;
      }
      svg {
        height: u.size('unit-5x');
      }
      img {
        height: u.size('unit-5x');
        width: auto;
      }
    }
  }

  .search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 u.size('unit-3x');
    flex: 1;
    position: relative;

    button {
      border: none;
    }

    @include u.below('md') {
      position: fixed;
      left: 0;
      right: 0;
      padding: 0;
      height: 100%;
      flex: auto;
      width: 0;
      box-sizing: border-box;
      z-index: u.z-index('before-modal');
      font-size: u.size('text-sm');
    }

    &-container {
      width: 100%;
      transition: transform .25s ease-in-out;
      @include u.below('md') {
        position: fixed;
        right: 0;
        top: 0;
        height: 100%;
        background-color: u.var-color('white');
        width: var(--header-mobile-block-width);
        z-index: u.z-index('before-modal');
        box-sizing: border-box;
        transition: transform .15s ease-in;
        padding: 0;
        transform: translate(var(--header-mobile-search-translate), 0);
        overflow: hidden;
        overflow-y: auto;
      }

    }


    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: u.var-color('body-overlay');
      opacity: 0;
      visibility: hidden;
      transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
      z-index: u.z-index('body-overlay');
      @include u.above('md') {
        display: none;
      }
    }

    @include u.mixins-block(open) {
      @include u.above('md') {
        :global #searchBoxInput {
          border-radius: u.size('unit') u.size('unit') 0 0 !important;
          border-width: 1px 1px 0 1px !important;
        }
      }
      :global #siteSearchAutocomplete {
        opacity: 1;
        visibility: visible;
      }
    }

    &-form {
      width: 100%;
      display: flex;
      align-items: flex-end;
      @include u.below('md') {
        background: url('~@Imageset/blocks/header/search.png') no-repeat;
        background-size: 100% auto;
        padding: u.size('unit-3x') u.size('unit') u.size('unit');
        height: u.size('unit-7x') * 2;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
      }

      &-title {
        font-size: u.size('text-md');
        color: u.var-color('white');
      }

      &-search {
        line-height: u.size('unit-5x') !important;
        height: u.size('unit-5x') !important;
        margin: 0 !important;
        padding: 0 u.size('unit-2x') !important;
        @include u.below('md') {
          padding: 0 u.size('unit') !important;
        }
      }
    }

    @include u.mixins-block(words) {
      overflow: hidden;

      width: 100%;
      @include u.above('md') {
        font-size: u.size('text-xs');
      }
      @include u.mixins-block(list) {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        height: u.size('unit-3x');
        padding: u.size('unit') 0 0;
        @include u.below('md') {
          height: auto;
          flex-direction: column;
          padding: 0 u.size('unit');
          overflow: hidden;
        }
        @include u.mixins-block(item) {

          @include u.above('md') {
            &:first-child {
              border-width: 0;
              padding: 0 u.size('unit') 0 0;
            }
            &:last-child {
              padding: 0 0 0 u.size('unit');
            }
          }
          @include u.below('md') {
            width: 100%;
            @include u.mixins-text-height(u.size('unit-6x'));
            border: none;
            padding: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          padding: 0 u.size('unit');
          border-style: solid;
          border-width: 0 0 0 1px;
          border-color: u.var-color('grey-10');
          @include u.mixins-block(link) {
            color: u.var-color('text-lt');
          }
        }
      }
    }
    @include u.mixins-block(helper) {
      width: 100%;
      position: relative;
      @include u.below('md') {

      }
    }

    @include u.mixins-block(autocomplete) {
      z-index: u.z-index('search-autocomplete');
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: u.var-color('white');
      opacity: 0;
      visibility: hidden;
      transition: u.change('visibility-transition-default');

      > :global(.ui-widget-content) {
        background: u.var-color('white');
        position: relative;
        @include u.below('md') {
          padding: 0 u.size('unit');
        }
        @include u.above('md') {
          max-height: 256px;
          border-color: u.var-color('grey-20');
          border-style: solid;
          border-width: 0 1px 1px;
          border-radius: 0 0 u.size('unit') u.size('unit');
          overflow-y: auto;
        }
      }

      @include u.mixins-block(list) {
        display: block;
        @include u.mixins-block(item) {
          @include u.mixins-text-height(u.size('unit-4x'));
          width: 100%;
          cursor: pointer;
          transition: background-color u.time('default') u.ease('default');
          display: flex;
          align-items: center;
          font-size: u.size('text-sm');
          @include u.below('md') {
            @include u.mixins-text-height(u.size('unit-6x'));
          }
          @include u.above('md') {
            padding: 0 u.size('unit') 0 u.size('unit-2x');
          }
          @include u.mixins-hover {
            background-color: u.var-color('grey-20');
          }
          @include u.mixins-block(text) {
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding: 0 u.size('unit-half') 0 0;
            @include u.mixins-block(hightlight) {
              font-weight: 700;
            }
          }
          @include u.mixins-block(counter) {
            font-size: u.size(text-xs);
          }
        }
      }
    }
  }

  html:global(.search-mobile-open) {
    @include u.below('md') {
      @include u.below('md') {
        .search {
          &-overlay {
            visibility: visible;
            opacity: 1;
          }

          &-container {
            transform: translate(0, 0);
          }
        }
      }
    }
  }

  .tools {
    display: flex;
    align-items: center;
  }

  .auth {
    position: relative;
    display: flex;
    align-items: center;
    font-size: u.size('text-sm');

    @include u.above('md') {
      a {
        color: u.var-color('text');
      }
    }

    @include u.mixins-block(login) {

      display: flex;
      align-items: center;
      margin: 0 0 0 u.size('unit-2x');
      padding: 0 0 0 u.size('unit-2x');
      border-left: 1px solid u.var-color('grey-10');
      svg {
        margin: 0 u.size('unit') 0 0;
      }
    }

    &-register {
      font-weight: 500;

    }

    @include u.mixins-block(window) {
      border-radius: u.size('unit');
      @include u.mixins-box-shadow-md(6);
      width: 400px;
      position: absolute;
      top: 100%;
      opacity: 0;
      visibility: hidden;
      right: 0;
      display: flex;
      flex-direction: column;
      @include u.mixins-hover {
        opacity: 1;
        visibility: visible;
      }
      align-items: center;
      transition: u.change('visibility-transition-default');
      z-index: u.z-index('auth-window');
      background: u.var-color('white');
      border: 1px solid u.var-color('grey-10');
      padding: u.size('unit-3x');
      @include u.mixins-block(extenrals) {
        padding: 0 0 u.size('unit-2x');
        margin: 0 0 u.size('unit-2x');
        border: 1px solid u.var-color('grey-1');
        width: 100%;
        :global .ulogin-buttons-container {
          display: flex !important;
          justify-content: space-between !important;
          width: 100% !important;

          [role="button"] {
            margin: 0 !important;
            border-radius: 100% !important;
            box-shadow: 0 0 5px u.var-color('grey-15');
          }
        }
      }
      @include u.mixins-block(forget-link) {
        color: u.var-color('text-ltr');
      }
      @include u.mixins-block(register) {
        width: 100%;
        text-align: center;
        @include u.mixins-block(link) {
          color: u.var-color('secondary');
        }
      }
    }
  }

  header {
    .auth-window-register-link {
      color: u.var-color('secondary') !important;
      margin: 0 0 0 u.size('unit');
    }
  }


  :global(#headerMenuBtn), :global(#headerSearchBtn) {
    display: none;
  }

  .logo {
    @include u.below('md') {
      display: none;
    }
  }
}


