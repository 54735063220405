@use "src/assets/scss/utils" as u;

.availability-check {
  z-index: 99999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e6e6e6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-text {
    margin: 0 0 u.size('unit-3x');
    text-align: center;
  }

  &-btn {
    width: 50%;
  }
}
