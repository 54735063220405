@use "src/assets/scss/utils" as u;


.modal {
  z-index: u.z-index('modal');

  &:global(.open) {
    opacity: 1;
    visibility: visible;
    height: 100%;
    transition: opacity .25s ease-out, visibility .25s ease-out, background-color .25s ease-in-out, height 1s step-start;
    //overflow-y: scroll;
    //background-color: rgba(#000, .3);
    &:before {
      opacity: 1;
      visibility: visible;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, .5);
    opacity: 0;
    visibility: hidden;
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
  }

  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  height: 0;
  visibility: hidden;
  overflow-y: auto;
  display: grid;
  align-content: center;
  transition: opacity u.time('default') ease-out, visibility u.time('default') ease-out, background-color u.time('default') ease-out, height .5s step-end;

  &-main {
    width: 100%;
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }

  &:global(.auto-width) {
    .modal-wrapper {
      width: auto;
      margin: auto;
      //@include u.below('sm') {
      //		margin: 0;
      //}
    }
  }

  &:global(.full-height) {
    align-content: initial !important;

    .modal-wrapper {
      height: 96%;
    }

    .modal-content {
      height: 100%;
    }

    .modal-container {
      flex: 1;
    }
  }

  @include u.mixins-block(wrapper) {
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 96%;
    margin: auto 0;
    justify-content: center;

    &-auto {
      width: auto;
      margin: 0 auto;
      @include u.below('sm') {
        margin: 0;
      }
    }
  }
  @include u.mixins-block(close) {
    position: absolute;
    right: u.size('unit-2x');
    top: u.size('unit');
    color: u.var-color('text');
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 0;


    @include u.mixins-hover {
      color: u.var-color('primary');
    }
    svg {
      @include u.square(u.size('unit-2x'));

      @include u.below('md') {
        @include u.square(u.size('unit-half-3x'));
      }
    }
  }

  @include u.mixins-block(content) {
    display: flex;
    flex-direction: column;
    padding: 0 u.size('unit-2x') u.size('unit-2x');

    @include u.below('md') {
      padding: 0 u.size('unit') u.size('unit');
    }
  }

  &-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: u.var-color('white');
    border-radius: u.size('unit');
    max-height: 100%;
    flex: 1;
    min-height: 0;

    &:global(.loading) {
      flex: auto !important;
      height: 100vh !important;
      background-color: transparent !important;
    }
  }

  &-header {
    width: 100%;
    height: u.size('unit-5x');
    min-height: u.size('unit-5x');
    display: flex;
    align-items: center;
    //padding: 0 u.size('unit-2x');
    justify-content: space-between;
    position: relative;
    font-size: u.size('text-md');
    color: u.var-color('text');

    &:global(.c-modal-header-action-enter) {
      opacity: 0;
    }

    &:global(.c-modal-header-action-enter-active) {
      opacity: 1;
      transition: opacity .25s ease-in-out;
    }

    &-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-weight: 500;
      padding: 0 u.size('unit') + 36px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }

    &-action {

    }

    @include u.below('md') {
      //padding: 0 u.size('unit');
    }

    .modal-close {
      position: initial;
      top: auto;
      right: auto;
      margin: 0 0 0 auto;

      @include u.square(36px);
    }

  }

  &:global(.is-mobile) {
    @include u.below('md') {
      align-content: initial !important;

      .modal-content {
        height: 100%;
      }
      .modal-container {
        flex: 1;
      }
      overflow: hidden;
      background-color: transparent !important;
      .modal-container {
        border-radius: u.size('unit') u.size('unit') 0 0;
      }

      .modal-wrapper {
        grid-column: full;
        margin: 0;
        max-height: 90%;
        transform: translateY(100vh);
        align-self: flex-end;
      }
      //&:global(.virtual-open) {
      //		.modal-wrapper {
      //				transform: translateY(0);
      //				transition: transform .25s ease-out;
      //		}
      //}
    }
  }

  &:global(.modal-transition) {

    .modal-wrapper {
      @include u.above('md') {
        opacity: 0;
        visibility: hidden;
      }

    }
  }

  &:global(.modal-transition-enter) {

    .modal-wrapper {
      @include u.above('md') {
        opacity: 0;
        visibility: hidden;
      }
    }

  }

  &:global(.modal-transition-enter-active) {

    .modal-wrapper {
      @include u.below('md') {
        transform: translateY(0);
        transition: transform .25s ease-out;
      }
      @include u.above('md') {
        opacity: 1;
        visibility: visible;
        transition: opacity .25s ease-out, visibility .25s ease-out;
      }
    }

  }

  &:global(.modal-transition-enter-done) {
    .modal-wrapper {
      @include u.below('md') {
        transform: translateY(0);
      }
      @include u.above('md') {
        opacity: 1;
        visibility: visible;
      }

    }

  }

  &:global(.modal-transition-exit) {

    @include u.above('md') {
      display: none !important;
    }

    .modal-wrapper {
      @include u.below('md') {
        transform: translateY(0);
      }
    }

    @include u.below('md') {
      background-color: transparent !important;
    }
  }

  &:global(.modal-transition-exit-active) {

    @include u.above('md') {
      opacity: 0;
      visibility: hidden;
      transition: opacity .25s ease-in, visibility .25s ease-in;
    }
    @include u.below('md') {
      &:before {
        opacity: 0;
        visibility: hidden;
        transition: opacity .25s ease-in, visibility .25s ease-in;
      }
      .modal-wrapper {
        transform: translateY(100vh);
        transition: transform .25s ease-in-out;
      }
    }
  }

  //&:global(.modal-transition-exit-done) {
  //
  //		@include u.below('md') {
  //				background-color: transparent !important;
  //				.modal-wrapper {
  //						transform: translateY(100vh);
  //				}
  //		}
  //}
  //&:global(.modal-transition-enter) {
  //		.modal-wrapper {
  //				@include u.above('md') {
  //						opacity: 0;
  //						visibility: hidden;
  //				}
  //		}
  //
  //}

  &:global(.close-ext) {
    @include u.above('md') {
      .modal-header {
        height: auto;
        min-height: 0;
      }
      .modal-close {
        width: 20px;
        height: 20px;
        position: absolute;
        margin: 0 0 0 u.size('unit');
        left: 100%;
        top: 0;
        color: u.color('grey-5');

        @include u.mixins-hover {
          color: u.var-color('primary-ltr');
        }

        svg {
          @include u.square(u.size('unit-half-3x'))
        }
      }
    }
  }
}

.mobile-modal {
  @include u.below('md') {
    z-index: u.z-index('modal');
    box-sizing: border-box;
    padding: u.size('unit-8x') 0 u.size('unit');
    width: 100vw;
    height: 0;
    &:global(.open) {
      opacity: 1;
      visibility: visible;
      height: 100%;
      transition: opacity u.time('default') ease-out, visibility u.time('default') ease-out, height .5s step-start;
      //overflow-y: scroll;
      background-color: rgba(#000, .5);
      overflow: hidden;

      > .mobile-modal-wrapper {
        transform: translateY(0);
        transition: transform .25s ease-in-out;
      }
    }
    //width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: [full-start] minmax(0, 1fr) [content-start] #{u.size('page-lg')} [content-end] minmax(0, 1fr) [full-end];
    align-content: flex-end;
    @include u.below('lg') {
      grid-template-columns: [full-start] minmax(0, 1fr) [content-start] #{u.size('page-md')} [content-end] minmax(0, 1fr) [full-end];
    }
    @include u.below('md') {
      grid-template-columns: [full-start] #{u.size('unit')} [content-start] calc(100% - 16px) [content-end] #{u.size('unit')} [full-end];
    }


    > * {
      grid-column: content;
      //padding: 0 var(--grid-container-gutter);
    }
    transition: opacity u.time('default') ease-out .15s, visibility u.time('default') ease-out .15s, background-color u.time('default') ease-out .15s, height 1s step-end;
    @include u.mixins-block(wrapper) {
      display: flex;
      flex-direction: column;
      padding: u.size('unit-2x');
      background: u.var-color('white');
      border-radius: u.size('unit');
      overflow: hidden;
      text-align: left;
      transform: translateY(100%);
      transition: transform .25s ease-in-out;
      @include u.mixins-box-shadow-md(6);
      @include u.below('sm') {
        padding: u.size('unit');
      }
      font-size: u.size('text-sm');
      line-height: u.size('unit-5x');
      color: #333;
      a {
        color: #333;
      }
      &:not(:first-of-type) {
        margin: u.size('unit-2x') 0 0;
      }
      @include u.mixins-block(auto) {
        width: auto;
        margin: 0 auto;
        @include u.below('sm') {
          margin: 0;
        }
      }
      > *:not(.mobile-modal-close) {
        overflow-y: auto;
      }
    }
  }

  &-close {
    display: none;
    justify-content: center;
    background-color: u.var-color('grey-10') !important;
    border-radius: u.size('unit');
    @include u.mixins-text-height(u.size('unit-5x'));
    border: none;
    color: u.var-color('secondary');
    margin: u.size('unit') 0 0;
    @include u.below('md') {
      display: flex;

    }
  }
}
