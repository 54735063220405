@use "src/assets/scss/utils" as u;


.header-bottom {
  @include u.below('md') {
    grid-area: bottom;
    background: u.var-color('white');
    display: flex;
    flex-direction: column;
  }
}

.nav {
  display: flex;
  flex-direction: column;
  width: 100%;


  @include u.below('md') {
    padding: 0;
  }


  &-wrapper {
    width: 100%;


    @include u.below('md') {
      position: relative;
    }
  }


  &-container {
    flex: 1;

    @include u.above('md') {
      position: relative;
    }


    @include u.below('md') {
      > .title {
        color: #fff;


        .item-icon {
          color: #fff;
        }
      }
    }
  }
}

.search-categories {
  width: 100%;
  background: linear-gradient(to right, u.var-color('primary-dk') 0%, u.var-color('primary-lt') 100%);
  @include u.above('md') {
    border-bottom: u.size('unit-half') solid #fff;
  }
  @include u.below('md') {
    //background: u.var-color('white');
    display: flex;
    flex-direction: column;
    padding: 0 u.size('unit');
  }


  .topLevel {
    @include u.mixins-hover {
      background: u.var-color('primary-dkr');


      > .title {
        color: #fff;
      }
      > .list {
        opacity: 1;
        visibility: visible;
        @include u.above('md') {
          transition: opacity .001s ease-in-out .5s, visibility .001s ease-in-out .5s;
        }
      }
    }
  }
}

.forum-categories {
  width: 100%;
  background: linear-gradient(to right, u.var-color('turquoise-dk') 0%, u.var-color('turquoise-lt') 100%);
  @include u.below('md') {
    //background: u.var-color('white');
    display: flex;
    flex-direction: column;
    padding: 0 u.size('unit');
  }

  .topLevel {
    @include u.mixins-hover {
      background: u.var-color('secondary-dkr');


      > .title {
        color: #fff;
      }
      > .list {
        opacity: 1;
        visibility: visible;
        @include u.above('md') {
          transition: opacity .001s ease-in-out .5s, visibility .001s ease-in-out .5s;
        }
      }
    }
  }
}

.main-list {
  width: 100%;
  position: relative;
  @include u.above('md') {
    display: grid;
    grid-template: "forum catalog" 1fr / auto 1fr;
  }
  @include u.below('md') {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

  }
}


.topLevel {
  transition: u.change('button-transition-default');

  &-icon {
    display: flex;
    margin: 0 u.size('unit-half-2x') 0 0;
    line-height: 1;

    svg {
      @include u.square(u.size('unit-half-3x'))
    }
  }

  &:global(.christmas) {
    @include u.below('md') {
      order: -1;
    }
  }

  @include u.above('md') {
    cursor: pointer;
    > .title {
      @include u.mixins-text-height(u.size('unit-5x'));
      color: u.var-color('white');
      font-weight: 500;

      padding: 0 u.size('unit-2x');
      text-align: center;
      display: flex;
      justify-content: center;

      //> .title-icon {
      //		margin: 0 u.size('unit');
      //}
    }
    > .list {
      position: absolute;
      top: 100%;
      opacity: 0;
      visibility: hidden;
    }
  }
  @include u.above('lg') {
    > .title {
      > .title-text {
        font-size: u.size('text-md') !important;
      }
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  @include u.mixins-block(group) {
    line-height: u.size('line-height-list');
    > .title {
      @include u.above('md') {
        padding: 0 0 u.size('unit');
        font-weight: 600;
      }
    }
  }

  @include u.mixins-block(icon) {
    display: none;
    color: u.var-color('primary');
    > svg {
      @include u.square(u.size('unit-3x'));
      margin: 0 u.size('unit-2x') 0 0;
    }
    @include u.below('md') {
      display: block;
    }
  }
  @include u.below('md') {
    & > .list {
      padding: 0 8px;
      min-height: 100%;
      height: 1px;
      width: 1px;
      top: 0;
      position: absolute;
      opacity: 0;
      left: 0;
      visibility: hidden;
      overflow: hidden;
      //clip: rect(1px, 1px, 1px, 1px);
      transform: translateX(100%);
      transition: opacity 0s .25s, visibility 0s .25s, width 0s .25s, height 0s .25s, transform .25s ease-in-out;
    }
  }
  @include u.mixins-block(visual-group) {
    line-height: u.size('line-height-list');
  }
}

.list {
  display: flex;
  flex-direction: column;

  &-row {
    @include u.above('md') {
      height: 100%;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      //display: grid;
      //grid-template-columns: repeat(auto-fit, minmax(0, auto));
      //grid-template-rows: u.size('unit-5x');
      margin: 0 -#{u.size('unit-2x')};
      position: relative;
    }
  }

  @include u.mixins-block(container) {
    @include u.above('md') {
      max-width: 100%;
      flex-direction: row;
      padding: u.size('unit-2x');
      background-color: u.var-color('white');
      border-radius: 0 0 u.size('unit') u.size('unit');
      @include u.mixins-box-shadow-md(6);
      z-index: u.z-index('before-header');
      font-size: u.size('text-sm');
      > .item {
        margin: 0 u.size('unit-5x') 0 0;

        &:last-child {
          margin: 0;
        }
      }
      @include u.mixins-block(position-left) {
        left: 0;
      }
      @include u.mixins-block(position-right) {
        right: 0;
      }
      @include u.mixins-block(position-auto) {
        left: auto;
      }


      .list {
        li {
          line-height: u.size('line-height-list') !important;
        }
      }
    }

  }
  @include u.mixins-block(indent) {
    > .item {
      @include u.above('md') {
        & + .item {
          margin: u.size('unit-3x') 0 0;
        }
      }

    }
  }
  @include u.below('md') {
    background-color: u.var-color('white');
    z-index: u.z-index('default');
    &:global(.open) {
      width: 100%;
      height: auto;
      opacity: 1;
      visibility: visible;
      clip: auto;
      transform: none;
      transition: transform .25s ease-in-out;


      > .item-visual-group {
        @include u.below('md') {
          > .list {
            width: 100%;
            height: auto;
            opacity: 1;
            visibility: visible;
            clip: auto;
            transform: none;
            position: static;
            overflow: visible;
          }
        }
      }
    }
  }
}

.title {
  display: flex;
  align-items: center;
  transition: u.change('button-transition-default');
  @include u.mixins-block(text) {
    display: flex;
    align-items: center;
    font-size: u.size('text-sm');
    @include u.below('md') {
      flex: 1;
      overflow: hidden;
      > span {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    //@include u.below('sm') {
    //		font-size: u.size('text-sm');
    //}
  }
  @include u.mixins-block(icon) {
    @include u.square(u.size('unit-3x'))
  }
  @include u.below('md') {
    cursor: pointer;
    @include u.mixins-text-height(u.size('unit-6x'));
  }

  &-deep-icon {
    @include u.square(u.size('unit-2x'));
    margin: 0 0 0 auto;
    padding: 0 0 0 u.size('unit');
    display: flex;
    align-items: center;
    box-sizing: content-box;
    @include u.above('md') {
      display: none;
    }
  }
}

.forum {
  grid-column: forum;
  @include u.mixins-block(list) {
    width: 100%;
    left: 0;
    > .item {
      margin: 0 u.size('unit-5x') 0 0;
    }


    @include u.mixins-block(categories) {
      > .list {
        flex-direction: row;
        flex: 1;
        justify-content: space-between;

        > .item {
          margin: 0 u.size('unit-5x') 0 0;

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }

  @include u.mixins-block(info) {
    background: u.var-color('grey-15');
    border-radius: u.size('unit-half');
    padding: u.size('unit');
    > .list {
      > .item {
        margin: 0 0 u.size('unit-2x');
        padding: 0 0 u.size('unit-2x');
        border-bottom: 1px solid u.var-color('grey-50');
        line-height: u.size('line-height-list');

        &:last-child {
          padding: 0;
          margin: 0;
          border: none;
        }
      }
    }
  }
}

.catalog {
  grid-column: catalog;
  @include u.below('md') {
    order: -1;
    //> .catalog-categories {
    //    position: absolute;
    //    top: 0;
    //    opacity: 0;
    //    visibility: hidden;
    //}
  }
  @include u.mixins-block(categories) {
    @include u.above('md') {
      height: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(0, auto));
      grid-template-rows: 1fr;
    }
  }
}


.helper {
  width: 100%;

  border-bottom: 1px solid u.var-color('grey-10');
  display: flex;
  align-items: center;
  grid-area: full;
  min-height: u.size('unit-6x');
  line-height: u.size('unit-6x');


  &:global(.active) {
    .helper-social {
      opacity: 0;
      visibility: 0;
      z-index: -1;
    }
  }


  &-wrapper {
    padding: 0 u.size('unit');
    width: 100%;
    display: flex;
    align-items: center;
    @include u.mixins-text-height(u.size('unit-6x'));
    position: relative;
  }


  &-button {
    margin: 0 u.size('unit-2x') 0 0;
    color: u.var-color('text') !important;
  }


  &-title {
    flex: 1;
    overflow: hidden;


    &-inner {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      text-align: center;
    }
  }


  &-social {
    height: 100%;
    width: 100%;
    background-color: u.var-color('white');
    z-index: u.z-index('default');
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    transition: opacity .15s ease-in-out, visibility .15s ease-in-out;

    &-item {
      flex: 1;
      display: flex;
      justify-content: center;

      &-link {
        padding: u.size('unit');

        svg {
          @include u.square(u.size('unit-2x'))
        }
      }
    }
  }
}

.additional {
  @include u.below('md') {
    padding: 0 u.size('unit');
  }

  .list {
    width: 100%;
    height: auto;
    opacity: 1;
    visibility: visible;
    clip: auto;
    transform: none;
    position: static;
    overflow: visible;
  }
}


:global(.search-react) {

  .header-bottom {
    @include u.below('md') {
      //grid-area: bottom;
      //background: u.var-color('white');
      //display: flex;
      //flex-direction: column;
      display: none;
    }
  }

  .nav {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include u.below('md') {
      padding: 0;
    }

    &-wrapper {
      width: 100%;

      @include u.below('md') {
        position: relative;
      }
    }

    &-container {
      flex: 1;

      @include u.above('md') {
        position: relative;
      }

      @include u.below('md') {
        > .title {
          color: #fff;

          .item-icon {
            color: #fff;
          }
        }
      }
    }
  }

  .search-categories {
    width: 100%;
    background: linear-gradient(to right, u.var-color('primary-dk') 0%, u.var-color('primary-lt') 100%);
    @include u.above('md') {
      border-bottom: u.size('unit-half') solid #fff;
    }
    @include u.below('md') {
      //background: u.var-color('white');
      display: flex;
      flex-direction: column;
      padding: 0 u.size('unit');
    }

    .topLevel {
      @include u.mixins-hover {
        background: u.var-color('primary-dkr');

        > .title {
          color: #fff;
        }
        > .list {
          opacity: 1;
          visibility: visible;
          @include u.above('md') {
            transition: opacity 0s ease-in-out, visibility 0s ease-in-out;
            transition-delay: 750ms;
          }
        }
      }
    }
  }

  .forum-categories {
    width: 100%;
    background: linear-gradient(to right, u.var-color('turquoise-dk') 0%, u.var-color('turquoise-lt') 100%);
    @include u.below('md') {
      //background: u.var-color('white');
      display: flex;
      flex-direction: column;
      padding: 0 u.size('unit');
    }

    .topLevel {
      @include u.mixins-hover {
        background: u.var-color('secondary-dkr');

        > .title {
          color: #fff;
        }
        > .list {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  .main-list {
    width: 100%;
    position: relative;
    @include u.above('md') {
      display: grid;
      grid-template: "forum catalog" 1fr / auto 1fr;
    }
    @include u.below('md') {
      display: flex;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;

    }
  }


  .topLevel {
    transition: u.change('button-transition-default');
    @include u.above('md') {
      cursor: pointer;
      > .title {
        @include u.mixins-text-height(u.size('unit-5x'));
        color: u.var-color('white');
        font-weight: 500;

        padding: 0 u.size('unit-2x');
        text-align: center;
        display: flex;
        justify-content: center;

        //> .title-icon {
        //		margin: 0 u.size('unit');
        //}
      }
      > .list {
        position: absolute;
        top: 100%;
        opacity: 0;
        visibility: hidden;
      }
    }
    @include u.above('lg') {
      > .title {
        > .title-text {
          font-size: u.size('text-md') !important;
        }
      }
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    @include u.mixins-block(group) {
      line-height: u.size('line-height-list');
      > .title {
        @include u.above('md') {
          padding: 0 0 u.size('unit');
          font-weight: 600;
        }
      }
    }

    @include u.mixins-block(icon) {
      display: none;
      color: u.var-color('primary');
      > svg {
        @include u.square(u.size('unit-3x'));
        margin: 0 u.size('unit-2x') 0 0;
      }
      @include u.below('md') {
        display: block;
      }
    }
    @include u.below('md') {
      & > .list {
        padding: 0 8px;
        min-height: 100%;
        height: 1px;
        width: 1px;
        top: 0;
        position: absolute;
        opacity: 0;
        left: 0;
        visibility: hidden;
        overflow: hidden;
        //clip: rect(1px, 1px, 1px, 1px);
        transform: translateX(100%);
        transition: opacity 0s .25s, visibility 0s .25s, width 0s .25s, height 0s .25s, transform .25s ease-in-out;
      }
    }
    @include u.mixins-block(visual-group) {
      line-height: u.size('line-height-list');
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;

    &-row {
      @include u.above('md') {
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        //display: grid;
        //grid-template-columns: repeat(auto-fit, minmax(0, auto));
        //grid-template-rows: u.size('unit-5x');
        margin: 0 -#{u.size('unit-2x')};
        position: relative;
      }
    }

    @include u.mixins-block(container) {
      @include u.above('md') {
        max-width: 100%;
        flex-direction: row;
        padding: u.size('unit-2x');
        background-color: u.var-color('white');
        border-radius: 0 0 u.size('unit') u.size('unit');
        @include u.mixins-box-shadow-md(6);
        z-index: u.z-index('before-header');
        transition: opacity u.time('default') u.ease('default'), visibility u.time('default') u.ease('default');
        font-size: u.size('text-sm');
        > .item {
          margin: 0 u.size('unit-5x') 0 0;

          &:last-child {
            margin: 0;
          }
        }
        @include u.mixins-block(position-left) {
          left: 0;
        }
        @include u.mixins-block(position-right) {
          right: 0;
        }
        @include u.mixins-block(position-auto) {
          left: auto;
        }

        .list {
          li {
            line-height: u.size('line-height-list') !important;
          }
        }
      }

    }
    @include u.mixins-block(indent) {
      > .item {
        @include u.above('md') {
          & + .item {
            margin: u.size('unit-3x') 0 0;
          }
        }

      }
    }
    @include u.below('md') {
      background-color: u.var-color('white');
      z-index: u.z-index('default');
      &:global(.open) {
        width: 100%;
        height: auto;
        opacity: 1;
        visibility: visible;
        clip: auto;
        transform: none;
        transition: transform .25s ease-in-out;

        > .item-visual-group {
          @include u.below('md') {
            > .list {
              width: 100%;
              height: auto;
              opacity: 1;
              visibility: visible;
              clip: auto;
              transform: none;
              position: static;
              overflow: visible;
            }
          }
        }
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
    transition: u.change('button-transition-default');
    color: u.var-color('text');
    @include u.mixins-block(text) {
      display: flex;
      align-items: center;
      font-size: u.size('text-sm');
      @include u.below('md') {
        flex: 1;
        overflow: hidden;
        > span {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      //@include u.below('sm') {
      //		font-size: u.size('text-sm');
      //}
    }
    @include u.mixins-block(icon) {
      @include u.square(u.size('unit-3x'))
    }
    @include u.below('md') {
      cursor: pointer;
      @include u.mixins-text-height(u.size('unit-6x'));
    }

    &-deep-icon {
      @include u.square(u.size('unit-2x'));
      margin: 0 0 0 auto;
      padding: 0 0 0 u.size('unit');
      display: flex;
      align-items: center;
      box-sizing: content-box;
      @include u.above('md') {
        display: none;
      }
    }
  }

  .forum {
    grid-column: forum;
    @include u.mixins-block(list) {
      width: 100%;
      left: 0;
      > .item {
        margin: 0 u.size('unit-5x') 0 0;
      }

      @include u.mixins-block(categories) {
        > .list {
          flex-direction: row;
          flex: 1;
          justify-content: space-between;

          > .item {
            margin: 0 u.size('unit-5x') 0 0;

            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }

    @include u.mixins-block(info) {
      background: u.var-color('grey-15');
      border-radius: u.size('unit-half');
      padding: u.size('unit');
      > .list {
        > .item {
          margin: 0 0 u.size('unit-2x');
          padding: 0 0 u.size('unit-2x');
          border-bottom: 1px solid u.var-color('grey-50');
          line-height: u.size('line-height-list');

          &:last-child {
            padding: 0;
            margin: 0;
            border: none;
          }
        }
      }
    }
  }

  .catalog {
    grid-column: catalog;
    @include u.below('md') {
      order: -1;
      //> .catalog-categories {
      //    position: absolute;
      //    top: 0;
      //    opacity: 0;
      //    visibility: hidden;
      //}
    }
    @include u.mixins-block(categories) {
      @include u.above('md') {
        height: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0, auto));
        grid-template-rows: 1fr;
      }
    }
  }


  .helper {
    width: 100%;

    border-bottom: 1px solid u.var-color('grey-10');
    display: flex;
    align-items: center;
    grid-area: full;
    min-height: u.size('unit-6x');
    line-height: u.size('unit-6x');

    &:global(.active) {
      .helper-social {
        opacity: 0;
        visibility: 0;
        z-index: -1;
      }
    }


    &-wrapper {
      padding: 0 u.size('unit');
      width: 100%;
      display: flex;
      align-items: center;
      @include u.mixins-text-height(u.size('unit-6x'));
      position: relative;
    }

    &-button {
      margin: 0 u.size('unit-2x') 0 0;
      color: u.var-color('text') !important;
    }

    &-title {
      flex: 1;
      overflow: hidden;

      &-inner {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: center;
      }
    }

    &-social {
      height: 100%;
      width: 100%;
      background-color: u.var-color('white');
      z-index: u.z-index('default');
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      transition: opacity .15s ease-in-out, visibility .15s ease-in-out;

      &-item {
        flex: 1;
        display: flex;
        justify-content: center;

        &-link {
          padding: u.size('unit');

          svg {
            @include u.square(u.size('unit-2x'))
          }
        }
      }
    }
  }

  .additional {
    @include u.below('md') {
      padding: 0 u.size('unit');
    }

    .list {
      width: 100%;
      height: auto;
      opacity: 1;
      visibility: visible;
      clip: auto;
      transform: none;
      position: static;
      overflow: visible;
      padding: 0;
      margin: 0;
    }
  }
}

