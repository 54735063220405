@use "src/assets/scss/utils" as u;
//@use '~@Scss/old/common/_mixins.scss' as *;
@use '~@Scss/old/common/_variables.scss' as *;

.site-footer {
  flex: 0 0 auto;
  width: 100%;
  background: #dedede;
  @include b(widget) {
    @include u.below('sm') {
      max-width: 400px;
      min-width: auto;
      width: 100%;
    }
  }

  &-desktop-version {
    @include u.above('md') {
      display: none;
    }
    width: 100%;
    display: flex;
    justify-content: center;
    order: 5;
    margin: u.size('unit-2x') 0 0;

    &-link {
      text-align: center;
      font-size: u.size('text-lg');
      color: u.color('text');
    }
  }

  @include b(subscribe-mobile) {
    display: flex;
    @include u.below('md') {
      margin: 0;
    }
    @include e(link) {
      display: flex;
      &:not(:last-child) {
        margin: 0 15px 0 0;
      }
    }
    @include e(image) {
      width: auto;
      height: 50px;
      @include u.below('lg') {
        height: 45px;
      }
    }
  }
  @include b(subscribe) {
    display: flex;
    flex-direction: column;
    margin: 0 0 30px;
    //@include u.below('md') {
    //  margin: 0 30px 0 0;
    //}
    @include u.below('sm') {
      margin: 0 0 15px;
    }
    @include b(list) {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      @include u.below('md') {
        justify-content: center;
      }
      @include b(item) {
        &:not(:last-child) {
          margin: 0 10px 0 0;
        }
        @include e(link) {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          background-color: #48484a;
          color: #fff;
          font-size: 20px;
          border-radius: 100%;
          transition: all .4s cubic-bezier(.4, 0, .6, 1.8);
          @include u.mixins-hover {
            transform: scale(1.25) !important;
          }
        }
      }
    }
    @include e(title) {
      margin: 0 0 15px;
      font-size: 20px;
      font-weight: 700;
      @include u.below('sm') {
        text-align: center;
      }
    }
  }
  @include b(info) {
    @include b(list) {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
      list-style: none;
      @include b(item) {
        &:not(:last-child) {
          margin: 0 0 5px;
        }
        @include e(link) {
          color: #666;
          font-size: 14px;
        }
      }
    }
    @include e(title) {
      margin: 0 0 15px;
      color: #333;
      font-size: 16px;
      font-weight: 700;
    }
  }
  @include b(overall) {
    width: 100%;
    background: #333;
    grid-column: full;
    @include b(list) {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      @include u.below('sm') {
        width: 100%;
        justify-content: space-around;
      }
      @include b(item) {
        text-align: center;
        &:not(:last-child) {
          margin: 0 30px 0 0;
          @include u.below('sm') {
            margin: 0 10px 0 0;
          }
        }
        @include e(link) {
          color: #666 !important;
          font-size: 14px;
          transition: .2s ease-in-out;
          @include hover-supported {
            color: $optwear-orange-strong;
          }
        }
      }
    }
    @include wrapper;
    @include e(main) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      padding: 12px 0;
    }
    @include e(column) {
      display: flex;
      flex-direction: column;
      @include u.below('sm') {
        width: 100%;
      }
      @include m(copyright) {
        flex-direction: row;
        @include u.below('sm') {
          justify-content: center;
          margin: 10px 0 0;
        }
      }
    }
    @include e(copyright) {
      color: #666 !important;
      font-size: 14px;
      transition: .2s ease-in-out;
      @include hover-supported {
        color: $optwear-orange-strong;
      }
    }
  }
  @include e(feedback) {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translate(1px, -50%) rotate(270deg);
    transform-origin: bottom right;
  }
  @include wrapper;
  @include e(main) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 20px 0 40px;
    @include u.below('sm') {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  @include e(column) {
    display: flex;
    flex-direction: column;
    @include m(subscribe) {
      //@include u.below('md') {
      //  order: 4;
      //  width: 100%;
      //  flex-direction: row;
      //  align-items: flex-end;
      //  justify-content: flex-start;
      //  margin: u.size('unit-2x') 0 0;
      //}
      @include u.below('sm') {
        align-items: center;
        width: 100%;
        order: 4;
        margin: u.size('unit-2x') 0 0;
      }
    }
    @include m(info) {
      flex-direction: row;
      @include u.below('sm') {
        padding: 0;
        order: 1;
        justify-content: space-around;
        width: 100%;
      }
      & > .site-footer-info {
        margin: 0 90px 0 0;
        @include u.below('lg') {
          margin: 0 60px 0 0;
        }
        @include u.below('md') {
          margin: 0 120px 0 0;
        }
        @include u.below('sm') {
          margin: 0;
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

@import "SiteFooterAds";
@import "-social-widget";
@import "../../lib/jwhelp";
