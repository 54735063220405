$font-path: '../../fonts/';
$font-path-montserrat: $font-path + 'Montserrat/';
$font-path-roboto: $font-path + 'Roboto/';

@font-face {
		font-family: Montserrat;
		src: url($font-path-montserrat + 'Montserrat-Regular.woff') format('woff'),
		url($font-path-montserrat + 'Montserrat-Regular.ttf')  format('truetype');
		font-weight: normal;
		font-display: swap;
}

@font-face {
		font-family: Montserrat;
		src: url($font-path-montserrat + 'Montserrat-Bold.woff') format('woff'),
		url($font-path-montserrat + 'Montserrat-Bold.ttf')  format('truetype');
		font-weight: 700;
		font-display: swap;
}

@font-face {
		font-family: Montserrat;
		src: url($font-path-montserrat + 'Montserrat-Medium.woff') format('woff'),
		url($font-path-montserrat + 'Montserrat-Medium.ttf')  format('truetype');
		font-weight: 500;
		font-display: swap;
}


@font-face {
		font-family: Roboto;
		src: url($font-path-roboto + 'Roboto-Regular.woff') format('woff'),
		url($font-path-roboto + 'Roboto-Regular.ttf')  format('truetype');
		font-weight: normal;
		font-display: swap;
}

@font-face {
		font-family: Roboto;
		src: url($font-path-roboto + 'Roboto-Bold.woff') format('woff'),
		url($font-path-roboto + 'Roboto-Bold.ttf')  format('truetype');
		font-weight: 700;
		font-display: swap;
}

@font-face {
		font-family: Roboto;
		src: url($font-path-roboto + 'Roboto-Medium.woff') format('woff'),
		url($font-path-roboto + 'Roboto-Medium.ttf')  format('truetype');
		font-weight: 500;
		font-display: swap;
}
