@use "src/assets/scss/utils" as u;

.news {
  width: 100%;
  padding: u.size('unit-3x') u.size('unit-2x') u.size('unit-2x');
  border-radius: u.size('unit');
  background-color: #fda10f;
  color: #fff;
  margin: 0 0 u.size('unit-3x');
  position: relative;
  @include u.mixins-box-shadow-md(6);
  box-sizing: border-box;

  @include u.below('sm') {
      padding: u.size('unit-2x') u.size('unit') u.size('unit');
      margin: 0 0 u.size('unit-2x');
  }

  @include u.below('xs') {
    margin: 0 0 u.size('unit');
  }

  * {
    box-sizing: border-box;
  }

  &-header {
    display: flex;
    align-items: center;
    margin: 0 0 u.size('unit-3x');

    @include u.below('sm') {
      margin: 0 0 u.size('unit-2x');
    }
  }

  &-close {
    cursor: pointer;
    color: #fff;
    margin: 0 0 0 auto;
    border: none;
    background: none;
    outline: none;
    transition: color .25s ease-in-out;

    @include u.mixins-hover() {
        color: u.color('grey-10');
    }

    svg {
      @include u.square(u.size('unit-2x'));
    }
  }

  &-wrap {
    width: 100%;
  }

  &-title {
    font-size: u.size('text-lg');
    margin: 0;
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  &-item {
    white-space: nowrap;
    width: 100%;
    font-size: u.size('text-md');

    @include u.below('xs') {
      font-size: u.size('text-sm');
    }

    &:not(:last-child) {
      margin: 0 0 u.size('unit-2x');

      @include u.below('sm') {
        margin: 0 0 u.size('unit');
      }
    }
  }
}
