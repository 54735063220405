@use "~@UtilsScss" as u;

.theme {
		@include u.mixins-block(hover) {
				@include u.mixins-block(secondary) {
						@include u.mixins-hover {
								color: u.var-color('secondary');
						}
						@include u.mixins-block(dk) {
								@include u.mixins-hover {
										color: u.var-color('secondary-dk');
								}
						}
				}
				@include u.mixins-hover {
						color: u.var-color('primary') !important;
				}

		}
}

header {
		.theme-hover-secondary-dk {
				@include u.mixins-hover {
						color: u.var-color('secondary-dk') !important;
				}
		}
}
