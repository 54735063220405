@use 'sass:color';
@use 'sass:meta';
@use '~accoutrement/sass/tools' as *;

$font-path: '../fonts/';

$ratios: (
        'text': 1.2
);

$text-sizes: (
        'root': 16px,
        'line-height': 1.4,
        'line-height-heading': 1.2,
        'line-height-list': 1.8,
        'text-ratio': ratio('text'),
        'text-xs': '#text-sm' ('divide': '#text-ratio'),
        'text-sm': '#root' ('divide': '#text-ratio'),
        'text-md': '#root',
        'text-lg': '#root' ('text': 1),
        'text-xl': '#root' ('text': 2),
        'text-xxl': '#root' ('text': 3),
);

$sizes: (
        'unit': 8px,
        'unit-half': '#unit' ('divide': 2),
        'unit-half-2x': '#unit' ('plus': '#unit-half'),
        'unit-half-3x': '#unit' ('plus': '#unit-half-2x'),
        'unit-2x': '#unit' ('times': 2),
        'unit-3x': '#unit' ('times': 3),
        'unit-4x': '#unit' ('times': 4),
        'unit-5x': '#unit' ('times': 5),
        'unit-6x': '#unit' ('times': 6),
        'unit-7x': '#unit' ('times': 7),
        'unit-8x': '#unit' ('times': 8),
        'unit-9x': '#unit' ('times': 9),
        'unit-10x': '#unit' ('times': 10),
        'page-lg': '#lg' ('minus': '#unit-4x'),
        'page-md': '#md' ('minus': '#unit-4x'),
);

$breakpoints: (
        'lg': 1200px,
        'md': 992px,
        'sm': 768px,
        'xs': 576px
);

$optwear-red: #F44336;
$optwear-green: #8BC34A;
$optwear-yellow: #FFEB3B;
$optwear-blue: #1565C0;
$optwear-orange: #FF9800;
$optwear-pink: #FF69B4;
$optwear-purple: #9C27B0;
$optwear-indigo: #3F51B5;
$optwear-cyan: #00BCD4;
$optwear-brown: #795548;
$optwear-grey: #9E9E9E;

$colors: (
        'blue': #0f52ba,
        'orange': #ea6b41,
        'primary': '#orange',
        'primary-lt': '#primary' ('scale-light': 15%),
        'primary-ltr': '#primary' ('scale-light': 30%),
        'primary-ltst': '#primary' ('scale-light': 50%),
        'primary-dk': '#primary' ('scale-light': -15%),
        'primary-dkr': '#primary' ('scale-light': -30%),
        'secondary': #00a5da,
        'secondary-lt': '#secondary' ('scale-light': 15%),
        'secondary-ltr': '#secondary' ('scale-light': 30%),
        'secondary-ltst': '#secondary' ('scale-light': 50%),
        'secondary-dk': '#secondary' ('scale-light': -15%),
        'secondary-dkr': '#secondary' ('scale-light': -30%),
        'green': #00b159,
        'success': '#green',
        'success-lt': '#success' ('scale-light': 15%),
        'success-ltr': '#success' ('scale-light': 30%),
        'success-dk': '#success' ('scale-light': -15%),
        'success-dkr': '#success' ('scale-light': -30%),
        'warning': #fabf25,
        'danger': #d11141,
        'danger-lt': '#danger' ('scale-light': 15%),
        'danger-ltr': '#danger' ('scale-light': 30%),
        'danger-dk': '#danger' ('scale-light': -15%),
        'black': #000,
        'white': #fff,
        'turquoise': #1d809b,
        'turquoise-lt': '#turquoise' ('scale-light': 15%),
        'turquoise-dk': '#turquoise' ('scale-light': -15%),
        'yellow': #FFEB3B,
        'yellow-lt': '#yellow' ('scale-light': 15%),
        'yellow-ltr': '#yellow' ('scale-light': 30%),
        'yellow-dk': '#yellow' ('scale-light': -15%),
        'yellow-dkr': '#yellow' ('scale-light': -20%),
        'text-dkr': '#black' ('tint': 5%),
        'text-dk': '#black' ('tint': 10%),
        'text': '#black' ('tint': 10%),
        'text-lt': '#black' ('tint': 30%),
        'text-ltr': '#black' ('tint': 50%),
        'grey-5': '#white' ('shade': 5%),
        'grey-10': '#white' ('shade': 10%),
        'grey-15': '#white' ('shade': 15%),
        'grey-20': '#white' ('shade': 20%),
        'grey-25': '#white' ('shade': 25%),
        'grey-30': '#white' ('shade': 30%),
        'grey-35': '#white' ('shade': 35%),
        'grey-40': '#white' ('shade': 40%),
        'grey-45': '#white' ('shade': 45%),
        'grey-50': '#white' ('shade': 50%),
        'grey-60': '#white' ('shade': 60%),
        'grey-70': '#white' ('shade': 70%),
        'white-opacity-30': '#white' ('rgba': 30%),
        'box-shadow-50': '#black' ('rgba': 50%),
        'box-shadow-14': '#black' ('rgba': 14%),
        'box-shadow-12': '#black' ('rgba': 12%),
        'box-shadow-20': '#black' ('rgba': 20%),
        'body-overlay': '#black' ('rgba': 50%),
        'body-background': '#grey-10',
        'body-background-rgb': '#body-background' ('hexToRGB': 'rgb'),
        'icon-color': '#grey-50',
        'overlay': '#black' ('rgba': 30%),
        'overlay-dk': '#black' ('rgba': 50%),
        'overlay-dkr': '#black' ('rgba': 70%),
        'outline': '#primary-lt'
);


$fonts: (
        'Montserrat': (
                'name': 'Montserrat',
                'stack': ('helvetica', 'arial', sans-serif),
        ),
        'Roboto': (
                'name': 'Roboto',
                'stack': ('helvetica', 'arial', sans-serif),
        )
);

$z-index: (
        'default',
        'after-default',
        'slider-button',
        'search-autocomplete',
        'preloader-low',
        'select',
        'before-header',
        'header-middle',
        'header-tools',
        'header',
        'header-top',
        'auth-window',
        'body-overlay',
        'before-modal',
        'modal',
);


$times: (
        'fast': .15s,
        'default': .25s,
        'long': .5s
);

$easing: (
        'default': '#_in-out',
        'text-default': '#_in-out'
);

$changes: (
        'link-transition-default': color time('default') ease('text-default'),
        'visibility-transition-default': (opacity time('default') ease('default'), visibility time('default') ease('default')),
        'visibility-transition-long': (opacity time('long') ease('default'), visibility time('long') ease('default')),
        'button-transition-default': (color time('default') ease('default'), background-color time('default') ease('default')),
        'label-color': (box-shadow time('fast') ease('default')),
        'thumb-box-shadow': (box-shadow time('fast') ease('default')),
        'button-non-bg': (color time('default') ease('default'))
);

@forward "~accoutrement/sass/tools";
@function _scale-light(
  $color,
  $percentage,
) {
  @return color.scale($color, $lightness: $percentage)
}

@function _hexToRGB($color, $format) {
  @return #{red($color)},#{green($color)},#{blue($color)};
}

$_scale-light-fn: meta.get-function('_scale-light');
$_hexToRGB-fn: meta.get-function('_hexToRGB');

@function get-color-value($key) {
  @return get-token($colors, $key);
}

@include register-function($_scale-light-fn, 'scale-light');
@include register-function($_hexToRGB-fn, 'hexToRGB');
@include register-function('rgba');
@include add-sizes($text-sizes, $breakpoints);
