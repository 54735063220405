@use './variables' as u;

@mixin block($el) {
		&-#{$el} {
				@content;
		}
}

@mixin mod($el) {
		&__#{$el} {
				@content;
		}
}

@mixin text-height($size) {
		height: $size;
		line-height: $size;
}

@mixin hover($focus: false) {
		@include u.above('md') {
				@if $focus {
						&:hover, &:focus {
								@content;
						}
				} @else {
						&:hover {
								@content;
						}
				}
		}
}

//@media (hover), (min-width: 0 \0
//), (min--moz-device-pixel-ratio: 0) {
//
//}

@mixin test {
		background: url('../../imageset/boy_boots.png');
}

@mixin absolute-center-horizontal {
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
}

@mixin absolute-center-vertical {
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
}

@mixin absolute-center {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
}

@mixin box-shadow-md($dp) {
		@if $dp==0 {
				box-shadow: none
		} @else if $dp==1 {
				box-shadow: 0 1px 1px 0 u.var-color('box-shadow-14'), 0 2px 1px -1px u.var-color('box-shadow-12'), 0 1px 3px 0 u.var-color('box-shadow-20')
		} @else if $dp==2 {
				box-shadow: 0 2px 2px 0 u.var-color('box-shadow-14'), 0 3px 1px -2px u.var-color('box-shadow-12'), 0 1px 5px 0 u.var-color('box-shadow-20')
		} @else if $dp==3 {
				box-shadow: 0 3px 4px 0 u.var-color('box-shadow-14'), 0 3px 3px -2px u.var-color('box-shadow-12'), 0 1px 8px 0 u.var-color('box-shadow-20')
		} @else if $dp==4 {
				box-shadow: 0 4px 5px 0 u.var-color('box-shadow-14'), 0 1px 10px 0 u.var-color('box-shadow-12'), 0 2px 4px -1px u.var-color('box-shadow-20')
		} @else if $dp==6 {
				box-shadow: 0 6px 10px 0 u.var-color('box-shadow-14'), 0 1px 18px 0 u.var-color('box-shadow-12'), 0 3px 5px -1px u.var-color('box-shadow-20')
		} @else if $dp==8 {
				box-shadow: 0 8px 10px 1px u.var-color('box-shadow-14'), 0 3px 14px 2px u.var-color('box-shadow-12'), 0 5px 5px -3px u.var-color('box-shadow-20')
		} @else if $dp==9 {
				box-shadow: 0 9px 12px 1px u.var-color('box-shadow-14'), 0 3px 16px 2px u.var-color('box-shadow-12'), 0 5px 6px -3px u.var-color('box-shadow-20')
		} @else if $dp==12 {
				box-shadow: 0 12px 17px 2px u.var-color('box-shadow-14'), 0 5px 22px 4px u.var-color('box-shadow-12'), 0 7px 8px -4px u.var-color('box-shadow-20')
		} @else if $dp==16 {
				box-shadow: 0 16px 24px 2px u.var-color('box-shadow-14'), 0 6px 30px 5px u.var-color('box-shadow-12'), 0 8px 10px -5px u.var-color('box-shadow-20')
		} @else if $dp==24 {
				box-shadow: 0 24px 38px 3px u.var-color('box-shadow-14'), 0 9px 46px 8px u.var-color('box-shadow-12'), 0 11px 15px -7px u.var-color('box-shadow-20')
		} @else {
				@error "Invalid argument for $dp: {" + $dp + "}, look at the method definition for possible values";
		}
}


