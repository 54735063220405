@use "src/assets/scss/utils" as u;
@forward '~accoutrement/sass/init';

a {
  color: u.var-color('text');
  text-decoration: none;
  outline: none;
  @include u.transition('link-transition-default');
  @include u.mixins-hover() {
    color: u.var-color('secondary');
  }
}

h1, h2, h3, .h1, .h2, .h3 {
  line-height: u.size('line-height-heading');
}

h1, .h1 {
  font-weight: 700;
  font-size: u.size('text-xxl');
}

h2, .h2 {
  font-weight: 700;
  font-size: u.size('text-xl');
}

h3, .h3 {
  font-weight: 500;
  font-size: u.size('text-lg');
}

//select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
//		&:focus {
//				@include u.below('md') {
//						font-size: 16px;
//				}
//		}
//}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

