.SiteFooterAds {
	background-color: lighten(#dedede, 5%);
	&__inner {
		width: $optwear-container-width;
		margin: 0 auto;
		padding-left: 0;
		padding-right: 0;
		display: flex;
		justify-content: center;
	}
}
