@use "src/assets/scss/utils" as u;

@each $point, $value in u.$breakpoints {
  .below-#{$point} {
    @include u.below($point) {
      display: none !important;
    }
  }
}

@each $point, $value in u.$breakpoints {
  .above-#{$point} {
    @include u.above($point) {
      display: none !important;
    }
  }
}

:global(.filter-modal-open) {
  .main {
    z-index: u.z-index('before-modal');
  }

  .wrapper {
    &:after {
      opacity: 1;
      visibility: visible;
    }
  }
}

//html:global(.modal-open) {
//		overflow: hidden;
//
//		body {
//				overflow: hidden;
//		}
//		.wrapper {
//				overflow: hidden;
//		}
//}

.wrapper {
  width: 100%;
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  overflow-x: hidden;

  box-sizing: border-box;
  position: relative;

  &-admin {
    overflow-x: auto;
  }

  @include u.below('md') {
    padding: 96px 0 0;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: u.var-color('body-overlay');
    opacity: 0;
    visibility: hidden;
    transition: opacity .35s ease-in-out, visibility .35s ease-in-out;
    z-index: u.z-index('body-overlay');
  }
}

.grid, :global(.grid) {
  display: grid;
  width: 100%;
  grid-template-columns: [full-start] minmax(0, 1fr) [content-start] #{u.size('page-lg')} [content-end] minmax(0, 1fr) [full-end];
  align-content: flex-start;
  @include u.below('lg') {
    grid-template-columns: [full-start] minmax(0, 1fr) [content-start] #{u.size('page-md')} [content-end] minmax(0, 1fr) [full-end];
  }
  @include u.below('md') {
    grid-template-columns: [full-start] #{u.size('unit')} [content-start] calc(100% - 16px) [content-end] #{u.size('unit')} [full-end];
  }

  > * {
    grid-column: content;
    //padding: 0 var(--grid-container-gutter);
  }

  .wide {
    grid-column: full;
  }
}

.main {
  padding: u.size('unit-2x') 0 u.size('unit-5x');
  @include u.below('md') {
    padding: u.size('unit') 0 u.size('unit-3x');
  }

  position: relative;
  //&:before, &:after {
  //	content: '';
  //	width: calc((100% - #{u.size('page-lg')}) / 2);
  //	height: 100%;
  //	position: absolute;
  //	top: 0;
  //	background-image: url('~@Assets/imageset/christmas-bg.svg');
  //	background-repeat: round space;
  //
  //	@include u.below('lg') {
  //		width: calc((100% - #{u.size('page-md')}) / 2);
  //	}
  //
  //	@include u.below('md') {
  //	    display: none;
  //	}
  //}

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

.mainAdmin {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;

  * {
    font-family: Roboto, Arial, sans-serif !important;
    font-size: 13px !important;
  }

  :global(#tabs) {
    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 u.size('unit');

      li {
        margin: u.size('unit-half') u.size('unit-half') 0 0;
      }
    }
  }

  ~ footer {
    :global(.site-footer__feedback) {
      display: none;
    }
  }
}

:global(.sp_ofice_in-page), :global(.sp_ofice_out-page) {
  .mainAdmin {
    * {
      font-size: 16px !important;
    }
  }

  td:global(.al) {
    text-align: left !important;
  }
}

:global(.search-sp-page), :global(.catalog-page), :global(.search-sp-old-page), :global(.catalog-old-page), :global(.category-page) {
  body {
    background-color: #fff !important;
  }
}

html:global(.auth) {
  .wrapper {
    @include u.below('md') {
      padding: 96px 0 u.size('unit-6x');
    }
  }
}
