@use "src/assets/scss/utils" as u;
@use "sass:meta";

.svg-icon, :global(.svg-icon) {
  fill: currentColor;
  width: 24px;
  height: 24px;
}

.logo {
  width: 175px;
  height: auto;
}

.logo-christmas {
  width: 175px;
  height: auto;
}

.icon-transition {
  position: relative;
  width: u.size('unit-3x');
  height: u.size('unit-3x');

  svg {
    width: u.size('unit-3x');
    height: u.size('unit-3x');
  }

  svg {
    left: 0;
    @include u.mixins-absolute-center;
    transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
    z-index: 2;
  }

  svg[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    z-index: 1;
  }
}

.static-click {
  opacity: 1 !important;
  visibility: visible !important;
}


.decor-text-in-line {
  width: 100%;
  padding: u.size('unit-3x') 0;
  @include u.mixins-block(wrapper) {
    width: 100%;
    position: relative;
    text-align: center;
    &:before {
      content: '';
      @include u.mixins-absolute-center-vertical;
      width: 100%;
      height: 2px;
      background-color: u.var-color('grey-15');
      z-index: 1;
      left: 0;
    }
  }
  @include u.mixins-block(text) {
    position: relative;
    z-index: 2;
    background-color: u.var-color(white);
    padding: 0 u.size('unit-half');

  }
}

header {
  .decor-text-in-line {
    padding: u.size('unit-3x') 0 !important;
  }
}

.breadcrumbs {
  width: 100%;
  order: -1;
  display: flex;
  align-items: center;
  padding: 0 0 u.size('unit-3x') 0;

  &:global(.white) {
    @include u.below('md') {
      &:after {
        background: linear-gradient(90deg, rgba(#fff, 0) 0%, #fff 100%);
      }
    }
  }

  @include u.below('md') {
    position: relative;
    flex-wrap: nowrap;
    margin: 0 0 u.size('unit');
    padding: 0;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: u.size('unit-3x');
      background: linear-gradient(90deg, rgba(u.get-color-value('body-background'), 0) 0%, u.var-color('body-background') 100%);
    }
  }
  @include u.mixins-block(list) {
    width: 100%;
    display: flex;
    align-items: center;
    @include u.mixins-text-height(u.size('unit-3x'));
    @include u.above('md') {
      overflow: hidden;
    }
    @include u.below('md') {
      @include u.mixins-text-height(u.size('unit-5x'));
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
    }

    @include u.below('xs') {
      @include u.mixins-text-height(u.size('unit-4x'));
    }
  }
  @include u.mixins-block(item) {
    position: relative;
    white-space: nowrap;
    @include u.below('md') {
      white-space: nowrap;
      &:last-child {
        padding-right: u.size('unit-3x');
      }
    }

    &:not(:last-child) {
      margin: 0 u.size('unit') 0 0;
      padding: 0 u.size('unit') 0 0;

      @include u.above('md') {
        min-width: u.size('unit-8x');
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:after {
        content: '';
        height: u.size('unit-2x');
        width: 1px;
        background: u.var-color('grey-25');
        @include u.mixins-absolute-center-vertical;
        right: 0;

        @include u.below('xs') {
          height: u.size('unit-2x') / 2;
        }

      }
    }
    @include u.mixins-block(link) {
      font-size: u.size('text-sm');
      color: u.var-color('text-lt');

      @include u.below('xs') {
        font-size: u.size('text-xs');
      }
    }

  }
}

.react-breadcrumbs {
  width: 100%;
  order: -1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  padding: 0 0 u.size('unit-3x') 0;

  &:global(.white) {
    @include u.below('md') {
      &:after {
        background: linear-gradient(90deg, rgba(#fff, 0) 0%, #fff 100%);
      }
    }
  }

  @include u.below('md') {
    &:before {
      content: '';
      background-color: #fff;
      width: 100%;
      height: 100%;
      transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    &:global(.init) {
      &:before {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  @include u.below('md') {
    position: relative;
    flex-wrap: nowrap;
    margin: 0 0 u.size('unit');
    padding: 0;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: u.size('unit-3x');
      background: linear-gradient(90deg, rgba(u.get-color-value('body-background'), 0) 0%, u.var-color('body-background') 100%);
    }
  }


  @include u.mixins-block(item) {
    position: relative;
    white-space: nowrap;
    @include u.below('md') {
      white-space: nowrap;
    }


    @include u.mixins-block(link) {
      font-size: u.size('text-sm');
      color: u.var-color('text-lt');

      @include u.below('xs') {
        font-size: u.size('text-xs');
      }
    }

  }

  :global(.swiper-container) {
    width: 100%;
  }

  :global(.swiper-slide) {
    width: auto;

    &:not(:last-child) {
      > .react-breadcrumbs-item {
        margin: 0 u.size('unit') 0 0;
        padding: 0 u.size('unit') 0 0;

        @include u.above('md') {
          min-width: u.size('unit-8x');
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:after {
          content: '';
          height: u.size('unit-2x');
          width: 1px;
          background: u.var-color('grey-25');
          @include u.mixins-absolute-center-vertical;
          right: 0;

          @include u.below('xs') {
            height: u.size('unit-2x') / 2;
          }

        }
      }

    }
  }
}

.substrate {
  padding: u.size('unit-2x');
  background: u.var-color('white');
  border-radius: u.size('unit');
  overflow: hidden;
  @include u.mixins-box-shadow-md(6);

  //&:not(:first-of-type) {
  //		margin: u.size('unit-2x') 0 0;
  //}

  @include u.below('sm') {
    padding: u.size('unit');
  }

}

.substrate-message {
  margin: u.size('unit-6x') auto;
  width: 680px;

  &-label {
    text-align: center;
    font-size: 20px;
    margin: 0 0 25px;
    font-weight: 700;
  }

  @include u.below('md') {
    width: 80%;
    margin: u.size('unit-3x') auto;
  }
  @include u.below('sm') {
    width: 100%;
    margin: 0 auto;
  }
}

.developmentAd {
  background: linear-gradient(to right, u.color('secondary') 0%, u.color('primary') 100%);
  position: relative;
  width: 100%;
  height: 100%;

  &:before {
    content: 'Реклама';
    color: #fff;
    font-size: 20px;
    font-weight: 900;
    @include u.mixins-absolute-center;
  }
}

.tooltip-text {
  text-decoration: underline;
  cursor: pointer;
}

.visuallyhidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.image-fit {
  object-fit: contain;
  font-family: 'object-fit: contain;';
  width: 100%;
  height: 100%;
}

.image-cover {
  object-fit: cover;
  font-family: 'object-fit: cover;';
  width: 100%;
  height: 100%;
}

.absolute-center {
  @include u.mixins-absolute-center;
}

.observer-animate-block {
  transition: transform .25s ease .05s;
  transform: translateY(50%);
  //opacity: 0;

  &:global(.init) {
    transform: translateY(0);
    //opacity: 1;
  }
}

.normalize {
  @include meta.load-css('normalize');

  a {
    text-decoration: none;
  }

  @include u.font-family('Montserrat');
}

.social-link {
  color: u.var-color('white') !important;
  display: flex;
  align-items: center;
  justify-content: center;
  @include u.mixins-box-shadow-md(6);
  transition: transform .25s ease-in-out;
  cursor: pointer;

  border-radius: 100%;

  @include u.mixins-hover {
    transform: scale(1.15);
  }

  &-facebook {
    background-color: #4267B2 !important;
  }

  &-viber {
    background-color: #7360f2 !important;
  }

  &-telegram {
    background-color: #0088cc !important;
  }

  &-odnoklassniki {
    background-color: #ed812b !important;
  }

  &-vkontakte {
    background-color: #4680C2 !important;
  }

  &-instagram {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%) !important;
  }

  &-yandex {
    background-color: #fff;
    color: #FF0000 !important;
  }

  &-google {
    background-color: #fff;
    color: #4285F4 !important;
  }

  &-mailru {
    background-color: #005FF9;
    color: #FF9E00 !important;
  }
}

:global {
  #yandex-header {
    display: flex;
    justify-content: center;
    height: u.size('unit-10x');
    overflow: hidden;
    width: 100%;
    @include u.below('xs') {
      height: u.size('unit-8x');
    }
  }

  .top-ads-block {
    order: -2;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 0 u.size('unit-2x');
    @include u.below('md') {
      //margin: 0 0 u.size('unit-2x');
      display: none;
    }
    overflow: hidden;
  }
}

:global {
  .error-404-page {
    .error-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      > * {
        &:not(:last-child) {
          margin: 0 0 u.size('unit-2x');
        }
      }

      &-logo {
        width: 300px;
      }

      &-title {
        font-weight: 500;
        font-size: u.size('text-lg');
      }
    }
  }
}

@keyframes preloader-animation {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}

@keyframes ld4 {
  0% {
    opacity: 0;
    transform: scale(0.5);

    background-color: u.var-color('primary-lt');
  }
  25% {
    opacity: 1;
    transform: scale(2);

    background-color: u.var-color('primary-ltr');
  }
  50% {
    opacity: 0;
    transform: scale(0.5);

    background-color: u.var-color('secondary');
  }
  75% {
    opacity: 1;
    transform: scale(1.8);

    background-color: u.var-color('secondary-lt');
  }
  100% {
    opacity: 0;
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.preloader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  transform-origin: 0 0;
  background-color: rgba(#000, .5);
  z-index: u.z-index('preloader-low');

  &-hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-in-out, visibility .5s ease-in-out;
  }

  &-fixed {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    transform: none;
    //height: calc(100vh - 221px);
    height: 100vh;
  }

  &-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-pulse {
    div {
      position: absolute;
      border-width: 4px;
      border-style: solid;
      opacity: 1;
      border-radius: 50%;
      animation: preloader-animation 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      box-sizing: content-box;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &:nth-child(1) {
        border-color: u.var-color('secondary')
      }

      &:nth-child(2) {
        border-color: u.var-color('primary');
        animation-delay: -0.5s;
      }
    }
  }

  &-balls {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      margin: 0 u.size('unit');
      height: u.size('unit');
      width: u.size('unit');
      border-radius: 50%;
      background: u.var-color('primary');

      &:nth-child(1) {
        animation: ld4 3s linear infinite 0s;
      }

      &:nth-child(2) {
        animation: ld4 3s linear infinite 0.15s;
      }

      &:nth-child(3) {
        animation: ld4 3s linear infinite 0.3s;
      }

      &:nth-child(4) {
        animation: ld4 3s linear infinite 0.45s;
      }
    }
  }

  &-balls-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;

    div {
      position: absolute;
      top: 50%;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: #fff;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);

      &:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
      }

      &:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
      }

      &:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
      }

      &:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
      }
    }
  }

  //div {
  //		position: absolute;
  //		border-width: 4px;
  //		border-style: solid;
  //		opacity: 1;
  //		border-radius: 50%;
  //		animation: preloader-animation 1s cubic-bezier(0,0.2,0.8,1) infinite;
  //		box-sizing: content-box;
  //		top: 50%;
  //		left: 50%;
  //		transform: translate(-50%, -50%);
  //		&:nth-child(1) {
  //				border-color: u.var-color('secondary')
  //		}
  //		&:nth-child(2) {
  //				border-color: u.var-color('primary');
  //				animation-delay: -0.5s;
  //		}
  //}


}

.skeleton {
  border-radius: u.size('unit');
  background-color: u.var-color('grey-25');

  &-space {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.avatar {
  border-radius: 100%;
  overflow: hidden;
  display: block;
  border: 1px solid u.color('grey-50');

  &-size {
    &-sm {
      @include u.square(u.size('unit-5x'));
    }

    &-md {
      @include u.square(u.size('unit-6x'));
    }

    &-lg {
      @include u.square(u.size('unit-8x'));
    }
  }
}

.order-line-status {
  text-align: center;

  &:global(.NEW_) {
    background-color: u.$optwear-green;
    color: #fff;
  }

  &:global(.FIXED) {
    background-color: u.$optwear-orange;
    color: #fff;
  }

  &:global(.INCLUDED) {
    background-color: u.$optwear-pink;
    color: #fff;
  }

  &:global(.DECLINED), &:global(.OUT_OF_STOCK) {
    background-color: u.$optwear-red;
    color: #fff;
  }

  &:global(.SORTING) {
    background-color: u.$optwear-blue;
    color: #fff;
  }

  &:global(.DELIVERED) {
    background-color: u.$optwear-brown;
    color: #fff;
  }

  &:global(.UNDELIVERED) {
    background-color: u.$optwear-purple;
    color: #fff;
  }

  &:global(.UNPAID) {
    background-color: u.$optwear-indigo;
    color: #fff;
  }
}

.autocomplete-users {
  &-item {
    width: 100%;
    cursor: pointer;
    padding: 0 u.size('unit');
    @include u.mixins-text-height(u.size('unit-6x'));
    display: flex;
    align-items: center;

    &:not(:global(.selected)) {
      @include u.mixins-hover {
        background-color: u.var-color('grey-5');
      }
    }

    &:global(.selected) {
      background-color: u.var-color('grey-10');
    }

    &-avatar {
      margin: 0 u.size('unit') 0 0;
    }

    &-label {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
