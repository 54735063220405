@use "src/assets/scss/utils" as u;


.header-top {
  background-color: u.var-color('grey-5');
  border-bottom: 1px solid u.var-color('grey-10');
  z-index: u.z-index('header-top');
  @include u.below('md') {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    &:global(.open) {
      transition: opacity .25s ease-in, visibility .25s ease-in;
      opacity: 1;
      visibility: visible;
    }
  }
}

.panel {
  display: grid;
  grid-template: "nav profile" 1fr / 1fr auto;

  @include u.below('md') {
    //display: flex;
    //flex-direction: column;
    grid-template: "profile" auto
							"nav" auto / 1fr;
  }
  //@include u.below('md') {
  //		grid-column: full;
  //		grid-template: "profile" auto "nav" auto / 100%;
  //		width: 100%;
  //		overflow: hidden;
  //		position: relative;
  //		&:after {
  //				content: '';
  //				position: absolute;
  //				width: u.size('unit-3x');
  //				height: u.size('unit-5x');
  //				background: linear-gradient(90deg, rgba(u.get-color-value('grey-5'), 0) 0%, u.var-color('grey-5') 100%);
  //				bottom: 0;
  //				right: 0;
  //		}
  //}
}

.nav {
  grid-column: nav;
  @include u.below('md') {
    margin: 0 0 u.size('unit-2x');
  }

  &-list {

    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    @include u.below('md') {
      flex-direction: column-reverse;
    }

    &-item {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 u.size('unit-2x');
      border-color: u.var-color('grey-10');
      border-style: solid;
      border-width: 0 1px 0 0;
      text-align: center;
      white-space: nowrap;

      &:global(.exit), &:global(.desktop) {
        @include u.below('md') {
          .nav-list-item-link {
            svg {
              margin: 0 u.size('unit') 0 0;
              @include u.square(u.size('unit-2x'));
            }
          }

        }
      }

      &:global(.exit) {
        @include u.below('md') {
          margin: 0 0 u.size('unit-8x');
          .nav-list-item-link {
            color: u.var-color('danger');
          }
        }

      }

      @include u.below('lg') {
        padding: 0 u.size('unit');
      }

      &:last-child {

        @include u.above('md') {
          padding: 0 0 0 u.size('unit-2x');
          border-width: 0;
        }

      }

      &:first-child {

        @include u.above('md') {
          border-width: 0 1px;
        }
      }

      &-icon {
        margin: 0 u.size('unit') 0 0;
        @include u.square(u.size('unit-2x'));
      }

      &-higthlight {
        font-weight: 700;

        a {
          color: u.var-color('secondary');
        }

        @include u.above('md') {
          padding: 0 u.size('unit') !important;
        }
        @include u.above('lg') {
          padding: 0 u.size('unit-2x') !important;
        }
      }

      @include u.below('md') {
        border-bottom: 1px solid u.var-color('grey-15');
      }

      @include u.mixins-block(link) {
        display: flex;
        align-items: center;
        color: u.var-color('text-lt');
        font-size: u.size('text-sm');
        @include u.mixins-text-height(u.size('unit-5x'));
        cursor: pointer;
        text-decoration: none;
        transition: .2s;
        @include u.below('md') {
          //@include u.mixins-text-height(50px);
          @include u.mixins-text-height(u.size('unit-6x'));
          width: 100%;
        }

        &-alfa {
          color: #EF3124 !important;

        }

        &-sber {
          color: #00be64 !important;
        }

        &-alfa, &-sber {
          @include u.mixins-hover {
            color: u.var-color('primary') !important;
          }
        }

      }

      @include u.mixins-block(counter) {
        margin: 0 0 0 u.size('unit');
        font-weight: 500;
      }
    }

    @include u.mixins-block(hover) {

      @include u.mixins-hover {
        > .nav-list-hover-sublist {
          opacity: 1;
          visibility: visible;
          transition-duration: .15s;
        }
      }

      @include u.above('md') {
        padding: 0 u.size('unit-2x');
        @include u.below('lg') {
          padding: 0 u.size('unit');
        }
        position: relative;
        display: flex;
        align-items: center;
        border-color: u.var-color('grey-10');
        border-style: solid;
        border-width: 0 1px;
        text-align: center;
        white-space: nowrap;
        color: u.var-color('text-lt');
        cursor: pointer;
      }

      @include u.mixins-block(sublist) {
        display: flex;
        flex-wrap: nowrap;
        padding: 0;
        margin: 0;


        transition: opacity .3s ease-in-out, visibility .3s ease-in-out;

        @include u.above('md') {
          position: absolute;
          top: 41px;
          left: -1px;
          z-index: 99999;
          opacity: 0;
          visibility: hidden;
          background-color: #f7f7f7;
          border-width: 0 1px 1px;
          border-style: solid;
          border-color: #e9e9e9;

        }

        @include u.below('md') {
          flex-direction: column;
        }

        > .nav-list-item {
          @include u.above('md') {
            padding-top: u.size('unit');
            padding-bottom: u.size('unit');
          }

          &:first-child {
            @include u.above('md') {
              border-width: 0 1px 0 0;
            }

            @include u.below('md') {
              margin-right: 0;
            }
          }

          &:last-child {
            @include u.above('md') {
              padding: 0 u.size('unit-2x');
            }
          }
        }
      }
    }
  }
}


.profile {
  position: relative;
  grid-column: profile;
  align-items: center;
  display: none;
  @include u.above('md') {
    cursor: pointer;
  }

  @include u.below('md') {
    overflow: hidden;
  }

  &-external {
    z-index: u.z-index('before-modal');
    width: 100%;
    position: fixed;
    bottom: 0;
    border-radius: u.size('unit') u.size('unit') 0;
    overflow: hidden;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: u.size('unit-8x');
    background-color: #fff;
    @include u.mixins-box-shadow-md(3);
    padding: 0 u.size('unit');

    &-btn {
      flex: 1;
      font-size: u.size('text-sm');

      &:not(:last-child) {
        margin: 0 u.size('unit-2x') 0 0;
      }
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    margin: u.size('unit-2x') 0 0;
    @include u.above('md') {
      display: none;
    }

    &-item {
      display: flex;
      align-items: center;
      color: #fff !important;

      svg {
        margin: 0 u.size('unit') 0 0;
      }

      &-id {
        font-weight: 500;
        margin: 0 u.size('unit-2x') 0 0;
        padding: 0 u.size('unit-2x') 0 0;
        border: none;
        border-right: 1px solid #fff;
        text-decoration: none;
      }
    }
  }

  //@include u.below('md') {
  //		grid-row: profile;
  //		padding: u.size('unit-3x') u.size('unit') u.size('unit');
  //		display: flex;
  //		flex-direction: column;
  //		align-items: flex-start;
  //		height: u.size('unit-7x') * 2;
  //}
  //@include u.below('xs') {
  //		grid-gap: 0 u.size('unit');
  //}
  //

  @include u.below('md') {
    order: -1;
    display: flex;
    flex-direction: column;
  }

  &-show-icon {
    margin: 0 0 0 u.size('unit');
    display: flex;
    align-items: center;
    transition: transform .25s ease-in-out;

    svg {
      @include u.square(u.size('unit-half-2x'));
    }
  }

  &-auth {
    display: flex;
    width: 100%;

    &-link {
      color: #fff !important;
    }

    &-login {
      font-weight: 500;
      margin: 0 u.size('unit-2x') 0 0;
      padding: 0 u.size('unit-2x') 0 0;
      border-right: 1px solid #fff;
    }
  }

  @include u.mixins-block(avatar) {
    @include u.square(u.size('unit-4x'));
    border-radius: 100%;
    overflow: hidden;
    border: 2px solid u.var-color('grey-50');
    background-position: 50%;
    background-color: u.var-color('white');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 u.size('unit') 0 0;
    @include u.below('md') {
      @include u.square(u.size('unit-8x'));
      margin: 0 0 u.size('unit');
    }
    @include u.mixins-block(no-auth) {
      color: u.var-color('primary');

    }
  }
  @include u.mixins-block(username) {
    font-size: u.size('text-sm');
    color: u.var-color('text-lt');
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include u.above('md') {
      max-width: 125px;
    }
    @include u.below('md') {
      color: #fff;
      width: 100%;
      font-size: u.size('text-md');
    }
  }

  &-container {
    display: flex;
    @include u.above('md') {
      align-items: center;
    }
    @include u.below('md') {
      width: 100%;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      background: url('~@Imageset/blocks/header/profile.png') no-repeat;
      background-size: 100% auto;
      min-height: 150px;
      padding: u.size('unit');
    }
  }

  &-options {

    @include u.above('md') {
      position: absolute;
      top: 100%;
      right: 0;
      opacity: 0;
      visibility: hidden;
      background-color: u.var-color('grey-15');
      border-radius: u.size('unit') 0 u.size('unit') u.size('unit');
      @include u.mixins-box-shadow-md(6);
      transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
      padding: u.size('unit-2x') 0;
      z-index: u.z-index('header-top');
    }

    @include u.below('md') {
      width: 100%;
      opacity: 1;
      visibility: visible;
      padding: 0;
      box-shadow: none;
      //font-size: u.size('text-md');
      display: none;
    }

    &-button {
      padding: u.size('unit-half') u.size('unit');
      color: #fff !important;
    }

    &-list {
      list-style: none;
      padding: 0;
      margin: 0;

      &-item {


        width: 100%;
        white-space: nowrap;

        &-link {
          color: u.var-color('text');
          display: block;
          @include u.mixins-text-height(u.size('unit-4x'));
          width: 100%;
          @include u.mixins-hover {
            color: u.var-color('primary');
            background-color: u.var-color('grey-10');
          }
          @include u.above('md') {
            font-size: u.size('text-sm');
          }
          padding: 0 u.size('unit-2x');
          transition: color .25s ease-in-out, background-color .25s ease-in-out;
          @include u.below('md') {
            padding: 0;
            @include u.mixins-text-height(40px);
          }
        }
      }
    }
  }
}

.mobile-close {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  position: fixed;
  top: 10px;
  right: 0;

  &:focus {
    box-shadow: none;
  }

  @include u.square(36px);

  svg {
    @include u.square(u.size('unit-half-3x'));
  }

  @include u.above('md') {
    display: none;
  }
}

html:global(.auth) {
  .profile {
    display: flex;

    @include u.above('md') {
      padding: 0 u.size('unit');
      border-left: 1px solid u.var-color('grey-10');
      &:global(.show-options) {
        background-color: u.var-color('grey-15');

        .profile-show-icon {
          transform: rotate(180deg);
        }

        .profile-options {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
