@use "./modules";
@use './blocks';
@use "./utils" as u;


:root {
  @include u.colors--;
  @include u.tokens--(u.$breakpoints, 'breakpoint-')
}

html {
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  &.document-block {
    height: 100vh;
    overflow: hidden;
  }

  @include u.below('md') {
    -webkit-overflow-scrolling: touch;
  }

  &:global(.document-block) {
    height: 100vh;
    overflow: hidden;

    body {
      overflow: hidden;
      @include u.above('md') {
        overflow: scroll;
      }

    }

  }

  &:global(.admin-report-page) {
    background-color: #fff;
    overflow-x: auto;
  }

}

body {
  @include u.below('md') {
    -webkit-overflow-scrolling: touch;
  }

  @include u.font-family('Montserrat');
  color: u.var-color('text');
  font-size: u.size('root');
  line-height: u.size('line-height');
  min-height: 100%;
  width: 100%;
  background-color: u.var-color('grey-10') !important;

  &[data-theme="dark"] {
    $colors: (
            'black': #fff,
            'white': #292929,
            'text': '#black' ('shade': 20%),
            'text-lt': '#black' ('shade': 40%),
            'grey-5': '#white' ('tint': 5%),
            'grey-10': '#white' ('tint': 10%),
            'grey-15': '#white' ('tint': 15%),
            'grey-20': '#white' ('tint': 20%),
            'grey-25': '#white' ('tint': 25%),
            'box-shadow-50': '#black' ('rgba': 50%),
            'box-shadow-14': '#black' ('rgba': 14%),
            'box-shadow-12': '#black' ('rgba': 12%),
            'box-shadow-20': '#black' ('rgba': 20%),
    );
    @include u.colors--($colors);
  }


  @include u.below('md') {
    --header-mobile-block-width: 50%;
    --header-mobile-search-translate: 150%;
    --header-mobile-menu-translate: -150%;
  }
  @include u.below('sm') {
    --header-mobile-block-width: 75%;
    --header-mobile-search-translate: 125%;
    --header-mobile-menu-translate: -125%;
  }
  @include u.below('xs') {
    --header-mobile-block-width: calc(100% - #{u.size('unit-8x')});
    --header-mobile-search-translate: calc(100% + #{u.size('unit-8x')});
    --header-mobile-menu-translate: calc(-1 * (100% + #{u.size('unit-8x')}));
  }
}

input, select, button, [role='listbox'], [role='button'], [role='checkbox'] {
  outline: none;
}
