@use "src/assets/scss/utils" as u;
//@import "@Scss/old/common/_variables.scss";
@mixin button-reset {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  box-sizing: border-box;
  &[disabled] {
    cursor: default;
  }
}

@mixin img-responsive {
  max-width: 100%;
  height: auto;
  display: block;
  max-height: 100%;
}

@mixin btn-variant($color, $background-start,$background-end,$direction, $border) {
  color: $color !important;
  background: linear-gradient($direction, $background-start, $background-end);
  border-color: $border;
  &:focus,
  &.focus {
    color: $color !important;
    background: linear-gradient($direction, darken($background-start, 5%), darken($background-end, 5%));
    background-color: darken($background-start, 5%);
    border-color: darken($border, 25%);
    text-decoration: none !important;
  }
  &:hover, &_hover {
    color: $color !important;
    background: linear-gradient($direction, darken($background-start, 5%), darken($background-end, 5%));
    background-color: darken($background-start, 5%);
    border-color: darken($border, 12%);
    text-decoration: none !important;

  }
  &.disabled,
  &[disabled] {
    background: linear-gradient(to left, #eaebec, darken(#eaebec, 25%));
    background-color: #eaebec;
    color: $color-text-invert !important;
  }
  &:active,
  &.active,
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background: linear-gradient($direction, $background-start, $background-end);
      background-color: $background-start;
      border-color: $border;
      transform: translateY(1px);
      text-decoration: none !important;
    }
  }
  &Plain {
    color: $color !important;
    background-color: $background-end;
    border-color: $border;
    transition: all 0.25s;

    &:focus,
    &.focus {
      color: $color !important;
      background-color: darken($background-end, 5%);
      border-color: darken($border, 25%);
      text-decoration: none !important;
    }

    &:hover, &.hover {
      color: $color !important;
      background-color: darken($background-end, 5%);
      border-color: darken($border, 12%);
      text-decoration: none !important;
    }

    &:active,
    &.active,
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus,
      &.focus {
        background-color: $background-end;
        border-color: $border;
        transform: translateY(1px);
        text-decoration: none;
      }
    }

    &.disabled,
    &[disabled] {
      background: $color-background;
      background-color: $color-background;
      border-color: transparent;
      color: $color-text-invert !important;
      cursor: default;

      &:hover,
      &:focus,
      &:active {
        transform: none;
        cursor: default;
        background: $color-background;
        background-color: $color-background;
        border-color: transparent;
        color: $color-text-invert !important;
      }
    }

  }
  &Border {
    color: $background-end !important;
    background-color: $optwear-white;
    border-color: $border;
    transition: color 100ms ease-in-out, background-color 100ms ease-in-out, border-color 100ms ease-in-out;
    font-family: "Lucida Grande", "Trebuchet MS", Helvetica, Arial, sans-serif;

    &:focus,
    &.focus {
      color: $color !important;
      background-color: darken($background-end, 5%);
      border-color: darken($border, 25%);
      text-decoration: none !important;
    }

    &:hover, &.hover {
      color: $color !important;
      background-color: darken($background-end, 5%);
      border-color: darken($border, 12%);
      text-decoration: none !important;
    }

    &:active,
    &.active,
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus,
      &.focus {
        background-color: $background-end;
        border-color: $border;
        transform: translateY(1px);
        text-decoration: none;
      }
    }

    &.disabled,
    &[disabled] {
      background: $color-background;
      background-color: $color-background;
      border-color: $background-end;
      color: $color-text-invert !important;
      cursor: default;

      &:hover,
      &:focus,
      &:active {
        transform: none;
        cursor: default;
        background: $color-background;
        background-color: $color-background;
        border-color: $background-end;
        color: $color-text-invert !important;
      }
    }

  }
}

@mixin btnHoverable {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 2px 6px;
  box-sizing: content-box;
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  &:hover,
  &:active,
  &:focus {
    background-color: darken(#efefef, 15%);
  }
}

@mixin block($block) {
  &-#{$block} {
    @content;
  }
}

@mixin element($element) {

  &__#{$element} {
    @content;

  }
}

@mixin modifier($modifier) {
  $selector: #{$modifier};
  &--#{$selector} {
    @content;
  }

}

@mixin b($block) {
  @include block($block) {
    @content;
  }
}

@mixin e($element) {
  @include element($element) {
    @content;
  }
}

@mixin m($modifier) {
  @include modifier($modifier) {
    @content;
  }
}

$custom-breakpoints: (
        lgm: 1201,
        lg: 1200,
        mdm: 993,
        md: 992,
        smm: 769,
        sm: 768,
        xsm: 577,
        xs: 576,
) !default;
$media-direction: max !default;

@mixin css_property($property, $value) {
  @if type_of($value) == string {
    @if $property == 'content' {
      #{$property}: $value;
    } @else {
      #{$property}: unquote($value);
    }
  } @else {
    #{$property}: $value;
  }
}

@function splitScreens($string, $seperator) {
  $string: inspect(quote($string));
  $sepId: str-index(quote($string), $seperator);
  @if ($sepId) {
    $array: (quote(str-slice($string, 0, $sepId - 1)), quote(str-slice($string, $sepId + 1)));
    @return $array;
  } @else {
    @return $string;
  }
}

//@media screen
@mixin media($properties, $orientation: false) {

  @each $property, $value in $properties {
    @if type_of($value) == 'map' {
      @each $screen, $val in $value {
        $screen: splitScreens($screen, '-');
        @if type_of($screen) == 'list' {
          $min_screen: nth($screen, 1);
          $max_screen: nth($screen, 2);
          @if $min_screen == min or $min_screen == max {
            $media-direction: $min_screen;
            @if $orientation {
              @if map-has-key($custom-breakpoints, $max_screen) {
                @media only screen and (#{$media-direction}-width: map-get($custom-breakpoints, $max_screen) + 'px') and (orientation: $orientation) {
                  @include css_property($property, $val);
                }
              } @else {
                @media only screen and (#{$media-direction}-width: $max_screen + 'px') and (orientation: $orientation) {
                  @include css_property($property, $val);
                }
              }
            } @else {
              @if map-has-key($custom-breakpoints, $max_screen) {
                @media only screen and (#{$media-direction}-width: map-get($custom-breakpoints, $max_screen) + 'px') {
                  @include css_property($property, $val);
                }
              } @else {
                @media only screen and (#{$media-direction}-width: $max_screen + 'px') {
                  @include css_property($property, $val);
                }
              }
            }
          } @else {
            @if $orientation {
              @if map-has-key($custom-breakpoints,  $min_screen) and map-has-key($custom-breakpoints,  $max_screen) {
                @media only screen and (min-width: map-get($custom-breakpoints, $min_screen) + 'px') and (max-width: map-get($custom-breakpoints, $max_screen) + 'px') and (orientation: $orientation) {
                  @include css_property($property, $val);
                }
              } @else if map-has-key($custom-breakpoints,  $min_screen) and not map-has-key($custom-breakpoints,  $max_screen) {
                @media only screen and (min-width: map-get($custom-breakpoints, $min_screen) + 'px') and (max-width: $max_screen + 'px') and (orientation: $orientation) {
                  @include css_property($property, $val);
                }
              } @else if not map-has-key($custom-breakpoints,  $min_screen) and map-has-key($custom-breakpoints,  $max_screen) {
                @media only screen and (min-width: $min_screen + 'px') and (max-width: map-get($custom-breakpoints, $max_screen) + 'px') and (orientation: $orientation) {
                  @include css_property($property, $val);
                }
              } @else {
                @media only screen and (min-width: $min_screen + 'px') and (max-width: $max_screen + 'px') and (orientation: $orientation) {
                  @include css_property($property, $val);
                }
              }
            } @else {
              @if map-has-key($custom-breakpoints,  $min_screen) and map-has-key($custom-breakpoints,  $max_screen) {
                @media only screen and (min-width: map-get($custom-breakpoints, $min_screen) + 'px') and (max-width: map-get($custom-breakpoints, $max_screen) + 'px') {
                  @include css_property($property, $val);
                }
              } @else if map-has-key($custom-breakpoints,  $min_screen) and not map-has-key($custom-breakpoints,  $max_screen) {
                @media only screen and (min-width: map-get($custom-breakpoints, $min_screen) + 'px') and (max-width: $max_screen + 'px') {
                  @include css_property($property, $val);
                }
              } @else if not map-has-key($custom-breakpoints,  $min_screen) and map-has-key($custom-breakpoints,  $max_screen) {
                @media only screen and (min-width: $min_screen + 'px') and (max-width: map-get($custom-breakpoints, $max_screen) + 'px') {
                  @include css_property($property, $val);
                }
              } @else {
                @media only screen and (min-width: $min_screen + 'px') and (max-width: $max_screen + 'px') {
                  @include css_property($property, $val);
                }
              }
            }
          }
        } @else {
          @if $screen == 'print' {
            @media print {
              @include css_property($property, $val);
            }
          } @else {
            @if $orientation {
              @if map-has-key($custom-breakpoints, $screen) {
                @media only screen and (#{$media-direction}-width: map-get($custom-breakpoints, $screen) + 'px') and (orientation: $orientation) {
                  @include css_property($property, $val);
                }
              } @else {
                @if $screen == all {
                  @media only screen and (orientation: $orientation) {
                    @include css_property($property, $val);
                  }
                } @else {
                  @media only screen and (#{$media-direction}-width: $screen + 'px') and (orientation: $orientation) {
                    @include css_property($property, $val);
                  }
                }
              }
            } @else {
              @if map-has-key($custom-breakpoints, $screen) {
                @media only screen and (#{$media-direction}-width: map-get($custom-breakpoints, $screen) + 'px') {
                  @include css_property($property, $val);
                }
              } @else {
                @if $screen == all {
                  @include css_property($property, $val);
                } @else {
                  @media only screen and (#{$media-direction}-width: $screen + 'px') {
                    @include css_property($property, $val);
                  }
                }
              }
            }
          }
        }
      }
    } @else {
      @if $property == 'extend' {
        @extend #{unquote($value)} !optional;
      } @else {
        @include css_property($property, $value);
      }
    }
  }
}

;

@mixin grid {
  //@include media($grid-size);
  display: flex;
  flex-direction: column;
  width: 100%;
  //max-width: 1200px;
  //padding: 0 16px;
  //margin: 0 auto;
  box-sizing: border-box;
}

@mixin wrapper {
  @include e(wrapper) {
    @include grid;
    @content;
  }
}

@mixin disable-default-reset-btn {
  @at-root {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }

    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }

    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
  }
}

@mixin hover-supported {
  @media (hover), (min-width: 0 \0
  ), (min--moz-device-pixel-ratio: 0) {
    &:hover {
      @content;
    }
  }
}


@mixin action-icon($initial, $after-action, $size) {
  @include e(action-icon) {
    font-family: "icomoon", sans-serif !important;
    font-style: normal;
    font-weight: normal;
    overflow: hidden;
    display: flex;
    width: #{$size}px;
    height: #{$size}px;
    font-size: #{$size}px;
    position: relative;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      @extend .icon-#{$initial}:before;
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      @extend .icon-#{$after-action}:before;
      transform: translate(0, 100%);
    }
    @content;
  }
}

@mixin change-action-icon {
  &__action-icon {
    &:before {
      transform: translate(0, -100%);
    }

    &:after {
      transform: translate(0, 0);
    }
  }
}

@mixin generate-hide-size-helper() {
  $sizes: 'xs', 'sm', 'md', 'lg';
  @each $size in $sizes {
    .hide-#{$size} {
      @include media((
              display: (#{$size}: none !important)
      ));
    }
  }
}

@mixin generate-only-size-helper() {
  $sizes: 'xs', 'sm', 'md', 'lg';
  @each $size in $sizes {
    .only-#{$size} {
      @include media((
              display: (min-#{$size}m: none !important)
      ));
    }
  }
}

@mixin absolute-center-horizontal {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
