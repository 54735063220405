@use "src/assets/scss/utils" as u;
@use 'sass:meta';


.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: u.z-index('header-top');
  grid-template: auto 40px auto / 1fr;
  box-sizing: border-box;
  position: relative;

  a {
    text-decoration: none;
  }
}

.switch, .notification {
  width: 100%;
  height: u.size('unit-5x');
  display: flex;
  justify-content: center;
  align-items: center;

  &-link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin: 0 u.size('unit-2x') 0 0;
    }
  }
}

.noty {
  width: 100%;
}

.switch {
  @media (min-device-width: (u.size('lg'))) {
    display: none;
  }
}

.notification {
  @include u.below('md') {
    display: none;
  }

  &-link {
    width: auto;
    height: auto;
  }
}

:global(.search-mobile-open), :global(.header-mobile-open) {
  header {
    z-index: u.z-index('body-overlay');
  }
}
