@use "src/assets/scss/utils" as u;


.header-mobile {
  width: 100%;
  z-index: u.z-index('header');
  position: relative;
  @include u.below('md') {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    //width: var(--header-mobile-block-width);
    width: 0;
    box-sizing: border-box;
    z-index: u.z-index('before-modal');
    display: grid;
    grid-template: 1fr / auto 1fr;
    font-size: u.size('text-sm');
  }

  &-top {
    background: url('~@Imageset/blocks/header/profile.png') no-repeat;
    background-size: 100% auto;
    @include u.above('md') {
      display: none;
    }
  }

  &-container {
    width: 100%;


    @include u.below('md') {
      transition: transform .25s ease-in-out;
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      width: var(--header-mobile-block-width);
      z-index: u.z-index('before-modal');
      background-color: u.var-color('white');
      transform: translate(var(--header-mobile-menu-translate), 0);
      overflow: hidden;
      overflow-y: auto;
    }

  }

  &-overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: u.var-color('body-overlay');
    opacity: 0;
    visibility: hidden;
    transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
    z-index: u.z-index('body-overlay');
  }
}

html:global(.header-mobile-open) {
  @include u.below('md') {
    .header-mobile {
      &-container {
        transform: translate(0, 0);
      }

      &-overlay {
        //transition: opacity .25s ease-in-out .1s, visibility .25s ease-in-out .1s;
        visibility: visible;
        opacity: 1;
      }
    }
  }

}

//body:global(.search-mobile-open) {
//		.search {
//				transform: translate(calc(var(--header-mobile-search-translate) - #{100%}), 0);
//		}
//}

:global(.modal-header-profile-options-open) {
  .header-mobile-container {
    overflow: visible;
  }
}
