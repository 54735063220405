@use "src/assets/scss/utils" as u;

.button, a.button {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  border: none;
  font-weight: 500;
  padding: 0 u.size('unit-2x');
  font-size: u.size('text-md');
  border-radius: u.size('unit');
  transition: u.change('button-transition-default');
  position: relative;

  @include u.mixins-block(flex) {
    flex: 1;
  }
  @include u.mixins-block(width) {
    width: 100%;
  }
  @include u.mixins-block(fill-primary) {
    background-color: u.var-color('primary');
    color: u.var-color('white');
    @include u.mixins-hover {
      background-color: u.var-color('primary-dk');
      color: #fff;
    }
  }
  @include u.mixins-block(fill-success) {
    background-color: u.var-color('success');
    color: u.var-color('white');
    @include u.mixins-hover {
      background-color: u.var-color('success-dk');
      color: #fff;
    }
  }
  @include u.mixins-block(fill-secondary) {
    background-color: u.var-color('secondary');
    color: u.var-color('white');
    @include u.mixins-hover {
      background-color: u.var-color('secondary-dk');
    }
  }
  @include u.mixins-block(lg) {
    @include u.mixins-text-height(u.size('unit-6x'));
    font-size: u.size('text-lg');
  }

  @include u.mixins-block(ghost-grey-25) {
    border: 2px solid u.var-color('grey-25');
    background-color: transparent;
    color: u.var-color('text');
    transition: background-color u.time('default') u.ease('default'), border-color u.time('default') u.ease('default'), color u.time('default') u.ease('default');
    @include u.mixins-hover {
      color: u.var-color('white');
      border-color: u.var-color('grey-25');
      background-color: u.var-color('grey-25');
    }
  }
  @include u.mixins-block(ghost-grey-50) {
    border: 2px solid u.var-color('grey-50');
    background-color: transparent;
    color: u.var-color('grey-50');
    transition: background-color u.time('default') u.ease('default'), border-color u.time('default') u.ease('default'), color u.time('default') u.ease('default');
    @include u.mixins-hover {
      color: #fff;
      background-color: u.var-color('grey-50');
      > * {
        color: #fff;
      }
    }
  }

  &-theme {
    &-primary {
      background-color: u.var-color('primary');
      color: #fff;

      &:focus {
        color: #fff !important;
        outline: none;
        box-shadow: inset 0 0 0 2px u.var-color('outline');
      }

      @include u.mixins-hover(false) {
        background-color: u.var-color('primary-dk');
        color: #fff !important;
      }

      &-reverse {
        background-color: u.var-color('grey-5');
        color: u.var-color('primary-dk');

        &:focus {
          outline: none;
          box-shadow: inset 0 0 0 2px u.var-color('outline');
        }

        @include u.mixins-hover(true) {
          background-color: u.var-color('grey-10');
          color: u.var-color('primary-dkr');
        }
      }
    }

    &-secondary {
      background-color: u.var-color('secondary');
      color: #fff;

      &:focus {
        outline: none;
        box-shadow: inset 0 0 0 2px u.var-color('secondary-dk');
      }

      @include u.mixins-hover(false) {
        background-color: u.var-color('secondary-dk');
        color: #fff;
      }

      &-reverse {
        background-color: u.var-color('grey-5');
        color: u.var-color('secondary-dk');

        &:focus {
          outline: none;
          box-shadow: inset 0 0 0 2px u.var-color('secondary');
        }

        @include u.mixins-hover(true) {
          background-color: u.var-color('grey-10');
          color: u.var-color('secondary-dkr');
        }
      }
    }

    &-success {
      background-color: u.var-color('success');
      color: #fff;

      &:focus {
        outline: none;
        box-shadow: inset 0 0 0 2px u.var-color('success-lt');
      }


      @include u.mixins-hover(false) {
        background-color: u.var-color('success-dk');
        color: #fff;
      }

      &-reverse {
        background-color: u.var-color('grey-5');
        color: u.var-color('success-dk');

        @include u.mixins-hover(true) {
          background-color: u.var-color('grey-10');
          color: u.var-color('success-dkr');
        }
      }
    }
  }

  &-content {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    //@include u.mixins-text-height(u.size('unit-4x'));
  }

  &-size {
    &-xl {
      @include u.mixins-text-height(u.size('unit-6x'));
      font-size: u.size('text-lg');
    }

    &-lg {
      @include u.mixins-text-height(u.size('unit-5x'));
      font-size: u.size('text-md');
    }

    &-md {
      @include u.mixins-text-height(u.size('unit-4x'));
      font-size: u.size('text-md');

    }

    &-sm {
      @include u.mixins-text-height(u.size('unit-3x'));

      font-size: u.size('text-sm');

    }
  }

  &-fill {

  }

  &-fit {
    flex: 1;
  }

  &-prefix {
    margin: 0 u.size('unit') 0 0;
    display: flex;
    align-items: center;
  }


  &:global(.disabled) {
    background-color: u.color('grey-30');
    @include u.mixins-hover() {
      background-color: u.color('grey-30');
    }
  }

}

header {
  .button-fill-primary {
    background-color: u.var-color('primary') !important;
    color: u.var-color('white') !important;
    @include u.mixins-hover {
      background-color: u.var-color('primary-dk') !important;
    }
  }
}

.input {
  width: 100%;
  //@include u.mixins-text-height(u.size('unit-4x'));
  padding: 0 u.size('unit');
  border: 1px solid u.var-color('grey-25');
  //font-size: u.size('text-md');
  background-color: u.var-color('white') !important;
  border-radius: u.size('unit') !important;
  outline: none;

  &:focus {
    border-color: u.var-color('outline');
    box-shadow: inset 0 0 0 1px u.var-color('outline');
  }

  @include u.mixins-block(lg) {
    @include u.mixins-text-height(u.size('unit-5x'));
    padding: 0 u.size('unit-2x');
  }

  @include u.mixins-block(right-icon) {
    padding: 0 u.size('unit-5x') 0 u.size('unit');
  }

  &-size {
    &-sm {
      @include u.mixins-text-height(u.size('unit-3x'));
      font-size: u.size('text-sm');
    }

    &-md {
      @include u.mixins-text-height(u.size('unit-4x'));
      font-size: u.size('text-md');
    }

    &-lg {
      @include u.mixins-text-height(u.size('unit-5x'));
      font-size: u.size('text-md');
    }
  }

  &-invalid {
    border-color: u.var-color('danger');
  }
}

.textarea {
  resize: vertical;
  width: 100%;
  padding: 0 u.size('unit');
  border: 1px solid u.var-color('grey-25');
  background-color: u.var-color('white') !important;
  border-radius: u.size('unit') !important;
  outline: none;
  max-height: 500px;
  min-height: 250px;

  @include u.below('md') {
    min-height: 150px;
    max-height: 400px;
  }

  &:focus {
    border-color: u.var-color('outline');
    box-shadow: inset 0 0 0 1px u.var-color('outline');
  }

  @include u.mixins-block(lg) {
    @include u.mixins-text-height(u.size('unit-5x'));
    padding: 0 u.size('unit-2x');
  }

  @include u.mixins-block(right-icon) {
    padding: 0 u.size('unit-5x') 0 u.size('unit');
  }

  &-size {
    &-sm {
      @include u.mixins-text-height(u.size('unit-3x'));
      font-size: u.size('text-sm');
    }

    &-md {
      @include u.mixins-text-height(u.size('unit-4x'));
      font-size: u.size('text-md');
    }

    &-lg {
      @include u.mixins-text-height(u.size('unit-5x'));
      font-size: u.size('text-md');
    }
  }

  &-invalid {
    border-color: u.var-color('danger');
  }
}

.input-search {
  &:placeholder-shown {
    & + .input-search-controls {
      & > .input-search-controls-times {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &:-ms-input-placeholder {
    & + .input-search-controls {
      & > .input-search-controls-times {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  @include u.mixins-block(wrapper) {
    width: 100%;
    position: relative;
  }
  @include u.mixins-block(controls) {
    padding: 0 u.size('unit-2x') 0 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    color: u.var-color('text-lt');
    @include u.mixins-block(times) {
      transition: u.change('visibility-transition-default');
    }
    @include u.mixins-block(search) {
      margin: 0 0 0 u.size('unit');
    }
    button {
      padding: 0 u.size('unit-half');
      font-size: u.size('text-lg');
    }
  }
}


.form {
  #{--form-group-indent}: u.size('unit-2x');
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  @include u.mixins-block(sm) {
    #{--form-group-indent}: u.size('unit');
  }
  @include u.mixins-block(group) {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 var(--form-group-indent);
    &:last-of-type {
      margin: 0;
    }
    @include u.mixins-block(lg) {
      margin: 0 0 calc(var(--form-group-indent) * 2);
    }
    @include u.mixins-block(inline) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &-size {
      &-lg {
        margin: 0 0 calc(var(--form-group-indent) * 2);
      }
    }


  }

  &-row {
    display: flex;
    width: 100%;

    > .form-group {
      flex: 1;
      width: auto;
      margin: 0 u.size('unit') 0 0;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.label {
  margin: 0 0 u.size('unit');
  font-size: u.size('text-md');
  @include u.mixins-block(sm) {
    font-size: u.size('text-sm');
  }

  @include u.mixins-block(xs) {
    font-size: u.size('text-xs');
  }

  &-size {
    &-xs {
      font-size: u.size('text-xs');
    }

    &-sm {
      font-size: u.size('text-sm');
    }

    &-md {
      font-size: u.size('text-md');
    }

    &-lg {
      font-size: u.size('text-lg');
    }
  }
}

.input-button {
  @include u.mixins-absolute-center-vertical;
  right: 0;
  margin: 0 u.size('unit');
  height: 100%;
  display: flex;
  align-items: center;
}

.checkbox {
  cursor: pointer;
  outline-color: u.var-color('outline');
  position: relative;
  //@include u.mixins-text-height(u.size('unit-3x'));
  padding: 0 0 0 u.size('unit-3x');
  display: flex;
  align-items: center;


  &-label {
    margin: 0;

    &:before {
      content: '';
      @include u.mixins-absolute-center-vertical;
      left: 0;
      width: u.size('unit-2x');
      border: 1px solid u.var-color('grey-25');
      height: u.size('unit-2x');
      background-color: u.var-color('grey-10');
      border-radius: u.size('unit-half') !important;
      z-index: 1;
    }

    &:after {
      content: '';
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%) rotate(45deg);
      z-index: 3;
      opacity: 0;
    }
  }

  &-original {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }

  &:global(.checked) {
    .checkbox-original {
      &:checked {
        ~ .checkbox-label {
          &:before {
            background-color: u.var-color('secondary');
          }

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  &:not(:global(.no-visual)) {
    .checkbox-original {
      &:checked {
        ~ .checkbox-label {
          &:before {
            background-color: u.var-color('secondary');
          }

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  &-size {
    &-xs, &-md {
      //@include u.mixins-text-height(u.size('unit-3x'));
      padding: 0 0 0 u.size('unit-3x');

      .checkbox-label {
        &:before {
          @include u.square(u.size('unit-2x'));
        }

        &:after {
          width: 4px;
          height: 8px;
          left: 6px;
        }
      }
    }

    &-lg {
      @include u.mixins-text-height(u.size('unit-4x'));
      padding: 0 0 0 u.size('unit-4x');

      .checkbox-label {
        &:before {
          @include u.square(u.size('unit-3x'));
        }

        &:after {
          width: 6px;
          height: 15px;
          left: 9px;
        }
      }
    }
  }

  &-theme {
    &-secondary {

    }

    &-primary {
      &:not(:global(.no-visual)), &:global(.checked) {
        .checkbox-original {
          &:checked {
            ~ .checkbox-label {
              &:before {
                background-color: u.var-color('primary');
              }
            }
          }
        }
      }
    }
  }
}

.select {
  position: relative;
  width: 100%;


  &:global(.show) {
    .select-input-icon {
      transform: rotate(180deg);
    }
  }

  &-native {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &-input {
    cursor: pointer;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;

    &:focus {

    }

    &-text {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-icon {
      transition: transform .25s ease-in-out;
      color: #212;
      margin: 0 0 0 u.size('unit');
      line-height: 0;

      svg {
        @include u.square(u.size('unit-half-2x'));
      }
    }


    &-original {
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0;
      visibility: hidden;
      width: 100%;
    }
  }

  &-list {
    position: absolute;
    top: calc(100% + #{u.size('unit-half')});
    left: 0;
    background-color: #fff;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    @include u.mixins-box-shadow-md(6);
    z-index: u.z-index('select');
    width: 100%;
    border-radius: u.size('unit');
    overflow: hidden;
    opacity: 0;
    visibility: hidden;

    &:global(.show) {
      opacity: 1;
      visibility: visible;
      transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
    }
  }

  &-option {
    cursor: pointer;
    padding: 0 u.size('unit');
    width: 100%;
    outline: none;
    @include u.mixins-text-height(u.size('unit-4x'));
    display: flex;
    align-items: center;

    &:not(:global(.selected)) {
      @include u.mixins-hover {
        background-color: u.var-color('grey-5');
      }
    }

    &-icon {
      margin: 0 0 0 u.size('unit');
      color: u.var-color('primary');

      svg {
        @include u.square(u.size('unit-2x'));
      }
    }

    &-label {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:global(.selected) {
      background-color: u.var-color('grey-10');
    }
  }


  &-size {
    &-sm {
      @include u.mixins-text-height(u.size('unit-3x'));
      font-size: u.size('text-sm');
    }

    &-md {
      @include u.mixins-text-height(u.size('unit-4x'));
      font-size: u.size('text-md');
    }

    &-lg {
      @include u.mixins-text-height(u.size('unit-5x'));
      font-size: u.size('text-md');
    }
  }
}

.field-error {
  font-size: u.size('text-sm');
  color: u.var-color('danger');
  margin: u.size('unit') 0 0;
}

:global {
  .noUi-target {
    height: u.size('unit');
    //width: calc(100% - #{u.size('unit-half')});
    margin: 0 auto;
    padding: 0 17px;
  }

  .noUi-connect {
    background-color: u.var-color('primary');
    transition: background-color .25s ease-in-out;
  }

  .noUi-horizontal {
    .noUi-handle {
      outline: none;
      width: 0;
      height: 0;
      right: -17px;
      top: -6px;
      border-radius: 100%;
      padding: u.size('unit');
      border: 2px solid u.var-color('primary');

      &:before, &:after {
        content: none;
      }
    }

    .noUi-handle-lower {
      right: -2px;
    }

    .noUi-handle-upper {
      right: -18px;
    }
  }
}

.autocomplete {
  position: relative;

  &-list {
    position: absolute;
    left: 0;
    background-color: #fff;
    margin: 0;
    @include u.mixins-box-shadow-md(6);
    z-index: u.z-index('select');
    width: 100%;
    border-radius: u.size('unit');
    overflow: hidden;
  }

  &:global(.bottom) {
    .autocomplete-list {
      top: calc(100% + #{u.size('unit-half')});
    }
  }

  &:global(.top) {
    .autocomplete-list {
      bottom: calc(100% + #{u.size('unit-half')});
    }
  }
}
