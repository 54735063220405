@use "src/assets/scss/utils" as u;
@import "../../common/variables";


.SiteFooter-social-widget {
  display: flex;
  min-height: 234px;
  min-width: 317px;
  @include u.below('sm') {
    justify-content: center;
  }
  z-index: 0;

  &__btn-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__btn {
    box-sizing: border-box;

    a {
      display: flex;
      justify-items: center;
      align-items: center;
      padding: 0.5rem 1em;

      svg {
        fill: #666;
        transition: .4s cubic-bezier(.4, 0, .6, 1.8);
      }
    }

    &:hover a svg {
      transform: scale(1.25, 1.25);
    }

    &.active:hover a svg {
      transform: none;
    }

    &.active a {
      border: 2px solid #666;
      background: darken(#dedede, 15%);
      border-right: 0;
      position: relative;
      left: 2px;

      svg {
        fill: #fff;
      }
    }
  }

  &__content {
    width: 260px;
    max-height: 230px;
    min-width: 248px;
    min-height: 212px;
    @include media((
            min-width: (sm: auto),
            max-width: (sm: auto),
    ));
    border: 2px solid #666;
    @include media((
            width: (md: 260px, lg: 240px, sm: auto),
            flex:(sm: 1)
    ));
  }

  &__end-point {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    & > a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.telegram {
  &__channel {
    padding: 3px;
    flex-grow: 2;
    align-items: center;
    display: flex;

    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
      background-color: #dedede;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }

    a {
      font-size: 16px;
      color: #666;
      font-weight: bold;
      text-decoration: none;
      display: block;
      width: 100%;
    }

    &:hover {
      background-color: #1d809b33;
    }
  }

  &__open {
    color: #1d809b;
    padding: 5px;
    transition: background-color 0.25s;
  }
}
